.Waveform {
  flex: 5 1 60rem;
  height: 6rem;
  overflow: hidden;
  position: relative;
}

.Waveform__inner {
  height: auto;
  overflow: hidden;
  opacity: 0;
  position: relative;
  transition: opacity 1.5s ease-in-out;

  &.is-visible {
    opacity: 1;
  }

  &.is-live:after {
    opacity: 1;
  }

  &.is-live:before {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    background-image: linear-gradient(
      to right,
      rgba(17, 17, 17, 0.95) 0%,
      rgba(17, 17, 17, 0.1) 20%,
      rgba(17, 17, 17, 0) 50%,
      rgba(17, 17, 17, 0.1) 80%,
      rgba(17, 17, 17, 0.95) 100%
    );
    left: 0;
  }
}

.Waveform__inner.Waveform__live {
  &:after {
    background: url('/images/icon-player-tower.svg') no-repeat;
    background-size: 100%;
    bottom: 0.2rem;
    content: '';
    display: block;
    height: 3.5rem;
    left: 50%;
    margin-left: -1rem;
    position: absolute;
    transition: opacity 1.5s linear;
    width: 2.1rem;
  }
}

.waveform__toggle {
  align-items: center;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: flex;
  height: 6rem;
  justify-content: center;
  left: 0;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  transition: background 0.25s linear;
  width: 100%;
  z-index: 5;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;

    .waveform__toggle__text {
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.waveform__toggle__text {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  font-size: $font-size--xsmall;
  font-weight: $font-weight--heavy;
  letter-spacing: 0.15em;
  padding: 5px 8px 3px 8px;
  opacity: 0;
  text-transform: uppercase;
  transition: opacity 0.25s linear;

  &:hover {
    opacity: 1;
  }
}

.waveform__timestamp {
  align-items: center;
  display: flex;
  height: 6rem;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-select: none;
}

.waveform__timestamp--end {
  left: auto;
  right: 0;
}

.waveform__timestamp__inner {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 2px 5px;
}

#waveform-canvas {
  background-color: $color-gray--superdark;
}

@media screen and (max-width: $screen-sm) {
  .Waveform,
  .waveform__timestamp {
    height: 4rem;
  }
}
