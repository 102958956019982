
@import './EllipsisButton/EllipsisButton';

@mixin buttonTypeStyling($type-color, $text-color: false) {
  $contrastColor: choose-contrast-color($type-color);

  background: $type-color;
  border: none;
  color: if($text-color, $text-color, $contrastColor);
  cursor: pointer;
  position: relative;

  &:hover {
    background: lighten($type-color, 5%);
    color: if($text-color, $text-color, $contrastColor);
  }

  &:focus {
    color: if($text-color, $text-color, $contrastColor);
  }

  &.Button--outline {
    background: none;
    color: $type-color;
    border: 0.1rem solid $type-color;

    &:hover {
      background: rgba($type-color, 0.1);
    }
  }

  &.Button--compact {
    border: 0.1rem solid $type-color;
  }
}

.Button {
  align-items: center;
  border: none;
  border-radius: 0.3rem;
  display: flex;
  font-weight: 300;
  font-size: 1.4rem;
  justify-content: center;
  letter-spacing: 0.05em;
  padding: 1rem 1.5rem 0.8rem;
  text-align: center;
  white-space: nowrap;
}

// the disabled class is for applying opacity only.  Tooltip doesn't play nice
// with the cursor hover css
.Button--disabled,
.button.disabled,
button.disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

a.Button--disabled {
  // Disabling the translucent button via opacity is a little too much
  &.Button--translucent {
    opacity: 0.8;
  }

  cursor: not-allowed;
}

button[disabled]:hover {
  cursor: not-allowed;
}

.Button--primary,
a.Button--primary {
  @include buttonTypeStyling($color-primary, #ffffff);
}

.Button--neutral {
  @include buttonTypeStyling($color-gray--light, $color-gray--dark);

  &.Button--outline {
    color: #666;
    font-weight: 400;
    border: 1px solid #ccc;
  }
}

.Button--warning {
  @include buttonTypeStyling($color-danger, #ffffff);
}

.Button--white {
  background: #ffffff;
  border: none;
  color: $color-primary;

  &:hover {
    background: darken(#ffffff, 3%);
    color: lighten($color-primary, 5%);
  }

  &.Button--outline {
    background: none;
    color: #ffffff;
    border: 0.2rem solid #ffffff;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &.Button--compact {
    border: 0.1rem solid;
  }
}
.Button--transparent,
a.Button--transparent {
  background: none;
  color: inherit;
  font-weight: 500;

  &:hover {
    color: $color-primary;
    text-decoration: none;
  }
}

.Button--translucent {
  @include buttonTypeStyling(rgba(255, 255, 255, 0.1), #fff);
}

.Button--superlight {
  @include buttonTypeStyling($color-gray--superlight);
}

.Button--light {
  @include buttonTypeStyling($color-gray--light);
}

.Button--small {
  font-size: 1.2rem;
  padding: 0.8rem 1rem 0.7rem;

  img {
    margin-right: 0.5rem;
    margin-top: -0.1rem;
    max-height: 1.1rem;
  }
}

.Button--medium {
  font-size: 1.6rem;
  padding: 1rem;
}

.Button--large {
  font-size: 1.6rem;
  line-height: 1;
  padding: 1.25rem 1.5rem;
}

.Button__icon {
  display: inline-block;
  height: 1.4rem;
  margin-left: 0.8rem;

  img {
    height: 100%;
  }
}

.Button__icon--append {
  margin-left: 0.5rem;
}

.Button__icon--prepend {
  margin-right: 0.7rem;
}

.Button--expand {
  width: 100%;
}

.Button--expand-height {
  height: 100%;
}

.Button--block {
  display: block;
  width: 100%;
}

.Button--rounded {
  border-radius: 100rem;
  min-width: 3rem;
}

.Button--compact {
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.Button--flex {
  display: flex;
  justify-content: center;
}

.Button--minimal {
  padding: 0.3rem 0.8rem 0.2rem;

  img {
    height: 1.3rem;
    margin-right: 0.5rem;
  }
}

.Button--uppercase {
  font-weight: 500;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.Button--link {
  background: transparent;
  border: none;
  color: $color-primary;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    text-decoration: underline;
  }
}
