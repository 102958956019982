.EllipsisButton {
  align-items: center;
  border-radius: 0.4rem !important;
  flex: 0 0 auto; // Avoid being squished/stretched if parent is a flex container
  min-height: 2.8rem;
  min-width: 3.2rem;
  opacity: 0.5;
  padding: 0.5rem;
  text-align: center;

  img {
    user-select: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  // Undo styling that makes button look clickable
  &.EllipsisButton--display-only {
    &:hover {
      background: initial;
      opacity: 0.5;
    }
  }
}

.EllipsisButton--isActive {
  background: rgba(0, 0, 0, 0.08) !important;
  opacity: 1;
}

.EllipsisButton--dark {
  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }
}

@media screen and (min-width: $screen-md) {
  .ListItem,
  .ReactVirtualized__Table__row,
  .Table__row {
    .Button,
    .EllipsisButton {
      opacity: 0.5;
    }

    &:hover {
      .EllipsisButton {
        opacity: 1;
      }
    }
  }
}
