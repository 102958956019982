.btn {
  border: none;
  border-radius: 0;
  font-weight: $font-weight--light;
  font-size: 1.8rem;
}

.btn-link {
  font-size: 1.4rem;
}

.btn.no-focus {
  &:focus,
  &:active:focus {
    border: inherit;
    color: #fff;
    outline: inherit;
  }
}

.btn-default {
  background: $color-gray;
  border: 4px solid $color-gray;
  color: #fff;

  &:focus,
  &:active,
  &:hover:active,
  &:active:focus {
    border: 4px solid #fff;
    background: $color-gray;
    color: #fff;
    outline: 2px solid $color-gray;
  }

  &:hover {
    background: lighten($color-gray, 10%);
    border: 4px solid lighten($color-gray, 10%);
    color: #fff;
    outline: none;
  }
}

.btn-primary {
  background: $color-primary;
  border: 4px solid $color-primary;
  color: #fff;

  &:focus,
  &:active,
  &:hover:active,
  &:active:focus {
    background: $color-primary;
    border: 4px solid #fff;
    color: #fff;
    outline: 2px solid $color-primary;
  }

  &:hover {
    background: lighten($color-primary, 10%);
    border: 4px solid lighten($color-primary, 10%);
    color: #fff;
    outline: none;
  }
}

.btn-light {
  background: #fff;
  border: 4px solid #fff;
  color: $color-primary;

  &:focus,
  &:active,
  &:hover:active,
  &:active:focus {
    background: rgba(245, 245, 245, 1);
    border: 4px solid $color-primary;
    color: $color-primary--active;
    outline: 2px solid rgba(250, 250, 250, 1) !important;
  }

  &:hover {
    background: rgba(250, 250, 250, 1);
    border: 4px solid rgba(250, 250, 250, 1);
    color: $color-primary--hover;
    outline: none;
  }
}

.btn-bordered {
  border: 2px solid #fff;
  padding: 0.5rem 1rem !important;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid #fff;
  }

  &:focus,
  &:active,
  &:hover:active,
  &:active:focus {
    background: rgba(255, 255, 255, 0.15);
    border: 2px solid #fff;
    outline: 0 !important;
  }
}

.btn-text--submitting {
  display: none;
}

.btn.disabled {
  background: $color-gray;
  border-color: $color-gray;
  color: #fff;

  &:focus,
  &:hover,
  &:active {
    background: $color-gray;
    border-color: $color-gray;
    color: #fff;
    outline: none;
  }

  .btn-text {
    display: none;
  }
  .btn-text--submitting {
    display: block;
  }
}

.btn.error {
  background: #666;

  &:hover {
    background: #686868;
  }

  &:focus,
  &:active {
    background: #666;
  }
}

.btn-sm {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;
  padding: 0.3rem 1rem;
}

.btn-lg {
  font-size: $font-size--xlarge;
  padding: 5px 16px;
}

.btn-group-flex {
  display: flex;

  .btn {
    flex: 1;
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.btn-group--vert {
  .btn + .btn {
    margin-top: 0.5rem;
  }
}

button::-moz-focus-inner {
  border: none;
}

button {
  border: 0;
  cursor: pointer;
  overflow: visible;
  color: #fff;
  text-align: center;

  // This works fine unless using inline text (wrapping doesn't work)
  &.link {
    color: unset;
    background: unset;
    font-size: unset;
    padding: 0;
    color: $color-primary;
  }
}

// Use for text that uses onClick handler instead of href (best used with span)
.link {
  color: $color-primary;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

.btn.green {
  background-color: #68b207;
}

.btn.green:hover,
.btn.green:focus {
  background-color: #81c63a;
  color: #fff;
}

.btn.icn {
  // background-image: url("./images/icn_cube_btn.png");
  background-repeat: no-repeat;
  background-position: 7px 5px;
  padding-left: 33px;
}

@media screen and (max-width: $screen-xs) {
  .btn-lg {
    font-size: $font-size--large--mobile;
  }

  .btn-primary:hover {
    background: $color-primary;
    border: 4px solid $color-primary;
    color: #fff;
    outline: 2px solid $color-primary;
  }
}
