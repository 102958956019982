.Input__container {
  flex: 1;

  input {
    flex: 1;
  }

  .Input__container__inner {
    flex: 1;
    position: relative;
  }
}

.Input__icon {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 4.4rem;
  z-index: 1;
}

.Input__icon--prepend {
  left: 0;
  opacity: 0.5;
}

.Input__icon--append {
  right: 0;
  outline: none;
}

.Input__footer {
  color: #666666;
  font-size: 1.1rem;
  line-height: 1.4;
  padding-left: 0.1rem;
  padding-top: 0.4rem;
}

.InputGroup label {
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
}

// Hides non standard X icon on Edge
// https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-clear
input::-ms-clear {
  display: none;
}
