.flex,
.d-flex {
  display: flex;
  width: 100%;
}

.d-inline {
  display: inline;
}

.d-block {
  display: block;
}

.flex--vertical {
  flex-direction: column;
}

.flex__grow {
  flex-grow: 1;
}

.flex__shrink {
  flex-shrink: 1;
}

.align-items-center {
  align-items: center;
}

@media screen and (min-width: $screen-sm) {
  .d-sm-flex {
    display: flex;
    width: 100%;
  }
}
