.banner-grid__item > .breaks {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-breaks-800.jpg');
}
.banner-grid__item > .downtempo {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-downtempo-800.jpg');
}
.banner-grid__item > .deephouse {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-deephouse-800.jpg');
}
.banner-grid__item > .electro {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-electro-800.jpg');
}
.banner-grid__item > .all {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-all-800.jpg');
}
.banner-grid__item > .electronica {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-electronica-800.jpg');
}
.banner-grid__item > .progressivehouse {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-progressivehouse-800.jpg');
}
.banner-grid__item > .techhouse {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-techhouse-800.jpg');
}
.banner-grid__item > .techno {
  background-image: url('https://storage.googleapis.com/proton-genres/ondemand-techno-800.jpg');
}
