$banner-width: 57.8rem;

.RadioTile__container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-bottom: 4rem;
  width: 100%;
}

.RadioTile__content {
  max-width: 57.8rem;
  margin: 0 auto;
  width: 100%;
}

.RadioTile__banner {
  align-items: center;
  background: lighten($color-gray--superdark, 5%);
  padding-bottom: 50.35%;
  height: 0;
  width: 100%;
  max-width: 100%;
  position: relative;

  img {
    user-select: none;
    width: 100%;
    @extend .clearfix;
  }
}

.RadioTile__banner__blank {
  align-items: center;
  color: #fff;
  cursor: default;
  display: flex;
  left: 0;
  position: absolute;
  text-align: center;
  user-select: none;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 3;

  & > div {
    width: 100%;
  }
}

.RadioTile__banner__artist {
  font-size: $font-size--xxlarge;
  font-weight: $font-weight--heavy;
  margin-bottom: 0.25em;
  white-space: nowrap;
}

.RadioTile__banner__title {
  font-size: $font-size--xxlarge;
  font-style: italic;
  margin: 0 1rem;
}

.RadioTile__banner__logo {
  bottom: 3rem;
  position: absolute;
  text-align: center;
  width: 100%;

  img {
    margin: 0 auto;
    width: auto;
  }
}
.RadioTile__bottom {
  background: $color-gray--superdark;
  color: #fff;
  display: flex;
  overflow: hidden;
  padding: 1.5rem;
  position: relative;
}

.RadioTile__button {
  flex: 0 0 auto;
  margin-right: 1rem;
}

.RadioTile__artist a,
.RadioTile__artist span {
  color: #fff;
  display: block;
  font-size: $font-size--large;
  font-weight: $font-weight--heavy;
  margin-bottom: 0.3rem;
  margin-top: 0.2rem;
}

.RadioTile__artist a:hover {
  text-decoration: underline;
}

.RadioTile__showinfo {
  a,
  span {
    color: #fff;
    font-size: $font-size--small;
    font-weight: $font-weight--light;
    letter-spacing: 0.035em;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.RadioTile__meta {
  flex: 1 0 auto;
}

.show__title,
.show__date {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.show__date {
  margin-left: 0.3rem;
}

.RadioTile__listeners {
  align-items: center;
  background-image: linear-gradient(
    to right,
    rgba(17, 17, 17, 0) 0,
    rgba(17, 17, 17, 0.95) 1rem,
    rgba(17, 17, 17, 1) 100%
  );
  display: flex;
  flex: 0 0 auto;
  font-weight: $font-weight--light;
  font-size: $font-size--small;
  height: 100%;
  padding: 0 2rem;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
}

@media screen and (max-width: $screen-sm) {
  .RadioTile__banner__artist {
    font-size: $font-size--xlarge;
  }

  .RadioTile__banner__title {
    font-size: $font-size--xlarge;
  }

  .RadioTile__bottom {
    padding: 1rem;
  }

  .RadioTile__artist a,
  .RadioTile__artist span {
    font-size: $font-size--medium--mobile;
    font-weight: $font-weight--heavy;
  }

  .RadioTile__showinfo {
    font-size: $font-size--small--mobile;
  }

  .RadioTile__listeners {
    font-size: $font-size--small--mobile;
  }

  .RadioTile__banner__artist {
    font-size: $font-size--xlarge--mobile;
  }

  .RadioTile__banner__title {
    font-size: $font-size--xlarge--mobile;
  }

  .RadioTile .PlayerButton {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 1.6rem;
  }
}
