.section__header {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.section__header--large {
  margin-bottom: 2.5rem;
  margin-top: 5rem;
}

.section__header__subtitle {
  @extend .header--large;
  font-weight: 200;

  a {
    background-image: linear-gradient(to bottom, rgba(102, 102, 102,.6) 50%, rgba(102, 102, 102, 0) 50%);
    background-repeat: repeat-x;
    background-size: 2px .1em;
    background-position: 0 1.07em;
    color: $color-gray;

    &:hover,
    &:active,
    &:focus {
      background-image: linear-gradient(to bottom, rgba(226, 96, 20,.6) 50%, rgba(226, 96, 20, 0) 50%);
      color: $color-links;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .section__header__title {
    font-size: $font-size--xlarge;
    margin: 1rem 0;
  }
  .section__header__subtitle {
    font-size: $font-size--medium;
  }
}
