/* Utility Menu ========================================================================== */
.feedback_support {
  display: block;
  position: absolute;
  right: 215px;
  top: 15px;
  color: #FFF;
  font-size: 10px;
  font-weight: $font-weight--heavy;
  padding: 5px 40px 5px 5px;
  background-color: #59A20F;
}

.feedback_support::after {
  content: "";
  display: block;
  position: absolute;
  right: 9px;
  top: 5px;
  width: 21px;
  height: 19px;
  background-position: -15px top;
}

.feedback_support:hover {
  color: #FFF;
}

.nav {
  position: absolute;
  right: 0;
  top: 0;
  float: left;
  height: 60px;
  width: 190px;
  background-color: #59A20F;
  text-align: right;
  font-weight: $font-weight--light;
}

.nav li.account_name {
  font-size: 24px;
  color: #FFF;
  line-height: 60px;
  padding: 0 35px 0 0;
}

.nav li.account_name::after {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 15px;
  top: 24px;
  background-position: left top;
}

.nav li.account_name::after {
  animation: none;
}

.nav li.account_name:hover::after {
  animation: rotate 1s;
  animation-iteration-count: 1;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-45deg);
  }
}

@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(-45deg);
  }
}

@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(-45deg);
  }
}

@-ms-keyframes rotate {
  0% {
    -ms-transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(-45deg);
  }
}

.nav .sub_nav {
  position: absolute;
  visibility: visible !important;
  left: -999em;
  top: 60px;
  float: left;
  right: 0;
  padding: 0 0 5px 0;
  width: 190px;
  z-index: 9999;
  background: #59A20F;
  font-size: 14px;
  font-weight: $font-weight--heavy;
  line-height: 32px;
}

.nav .sub_nav li {
  float: left;
  clear: both;
  width: 100%;
  line-height: 32px;
  padding: 0;
}

.nav .sub_nav li a {
  display: block;
  float: left;
  text-align: right;
  width: 100%;
  padding: 0 35px 0 10px;
  line-height: 32px;
  color: #FFF;
}

.nav .sub_nav li a:hover {
  background-color: #68B207;
}

.nav li:hover {
  cursor: pointer;
}

.nav li:hover ul,
.nav li.sfhover ul {
  left: auto;
}