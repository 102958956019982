////////////////////////
// HEADER
.TabbedHeader {
  &.no-image {
    .TabbedHeader__header__body {
      margin-left: 0;
    }
  }

  .TabbedHeader__header__actions {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

.TabbedHeader__header__tabs {
  bottom: 0;
  left: 0;
  position: absolute;
}

.TabbedHeader__canvas {
  background: #fff;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

// Temporary fix to avoid a 1px weirdness gap in the switchover relative to our
// javascript breakpoints
