.App {
  min-height: 100vh;
  padding-top: 5.4rem; // header height desktop
}

@media screen and (max-width: $screen-md) {
  .App {
    padding-top: 4.4rem;
  }
}
