.UserSummaryTable {
  display: flex;
  flex: 2 1 0;
  flex-direction: column;
}

.UserSummaryTable__row {
  display: flex;

  &:first-child {
    .UserSummaryTable__block {
      border-top: 1px solid #ddd;

      div {
        padding-top: 1rem;
      }
    }
  }

  &:last-child {
    .UserSummaryTable__block {
      border-bottom: 1px solid #ddd;

      div {
        padding-bottom: 1rem;
      }
    }
  }
}

.UserSummaryTable__block {
  border-right: 1px solid #ddd;
  display: flex;
  flex: 1;
  overflow: hidden;

  &:first-child {
    margin-right: 0.5rem;
  }

  div:first-child {
    background: #f9f9f9;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    flex: none;
    font-weight: 600;
    text-align: center;
    width: 2rem;
  }

  div {
    padding-top: 0.5rem;
  }

  div:last-child {
    background: #ffffff;
    padding-right: 0.7rem;
    padding-left: 0.7rem;
    width: 100%;
  }
}

.UserSummaryTable__block:last-child {
  margin-left: 0.5rem;
}

.UserSummaryTable__row--mobile {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-bottom: 1rem;
  }

  .UserSummaryTable__block {
    border: none;
    margin: 0;

    div {
      border: none;
    }

    div:first-child {
      border-right: 1px solid #ddd;
    }

    &:last-child {
      div {
        padding-bottom: 0.5rem;
      }
    }
  }
}
