.Player {
  bottom: 0;
  height: $player-height;
  position: fixed;
  width: 100%;
  z-index: 5;
}

.Player__content {
  align-items: center;
  background: $color-gray--superdark;
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  height: 6rem;
  left: 0;
  width: 100%;
}

.Player__banner-outer {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  // min-width: 9.5rem; // Will this have any negative consequences removing?

  margin: 0 0 0 2rem;
  user-select: none;
  z-index: 5;
}

.Player__banner-inner {
  border-radius: 0.2rem;
  height: 4.8rem;
  overflow: hidden;

  img {
    height: 4.8rem;
  }
}

.Player__meta {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  outline: none;
  overflow: hidden;
  padding: 0.75rem 1rem;
  position: relative;
  width: 40rem;
  white-space: nowrap;
  z-index: 5;
}

.Player__meta__inner {
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: $screen-sm) {
    align-items: center;
    display: flex;
  }
}

.Player__meta a {
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
}

.Player__title {
  font-size: 1.6rem;
  font-weight: $font-weight--heavy;
  padding: 0.5rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Player__subtitle {
  font-weight: $font-weight--light;
  padding-bottom: 0.5rem;
}

.Player__waveform-container {
  flex: 5 1 60rem;
  overflow: hidden;
  position: relative;

  &.is-not-seekable {
    cursor: not-allowed;
  }
}

.Player__volume-trigger-container {
  flex: 0 0 4rem;
  margin: 0 1rem 0 0;
}

.Player__button-container {
  flex: 0 0 auto;
  margin: 0.75rem 0 0.75rem 1.5rem;
  z-index: 5;
}

.Player__tracklist {
  background: $color-gray--superdark;
  border-bottom: 1px solid lighten($color-gray--superdark, 10%);
  color: #fff;
  height: 17rem;
  left: 0;
  bottom: -11rem;
  position: fixed;
  transition: transform 0.25s ease-in-out, visibility 0.25s;
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  width: 100%;
  z-index: -1;

  &.is-visible {
    transform: translate3d(0, -17rem, 0);
    visibility: visible;
  }
}

.Player__action {
  color: #fff;
  cursor: pointer;
  flex: 0 0 auto;
  font-weight: $font-weight--heavy;
  letter-spacing: 0.15em;
  line-height: 6rem;
  opacity: 0.9;
  outline: none;
  padding: 0 1.5rem;
  text-transform: uppercase;
  user-select: none;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }

  &:hover {
    opacity: 1;
  }
}

.Player__action__trigger {
  &.is-disabled {
    opacity: 0.5;
    &:hover,
    &:focus,
    &:active {
      opacity: 0.5;
    }
  }
}

.Player__button {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 1rem 0 2rem;
}

@media screen and (max-width: $screen-sm) {
  .Player {
    height: auto;
  }

  .Player__button-container {
    margin: 0.4rem 0 0.4rem 0.5rem;

    .PlayerButton {
      height: 3.2rem;
      width: 3.2rem;
      border-radius: 1.6rem;
    }
  }

  .Player__content {
    height: $player-height--mobile;
  }

  .Player__banner-outer {
    margin: 0 0.5rem 0 0.5rem;
    min-width: none;
    padding: 0.4rem 0;
    user-select: none;
    flex: 0 1 auto;
    width: auto;

    img {
      height: 3.2rem;
    }
  }

  .Player__banner-inner {
    height: auto;
  }

  .Player__meta {
    flex: 1 1 30rem;
    margin-left: 0.5rem;
    min-width: auto;
    padding: 0.5rem 0;
  }

  .Player__meta__more-icon {
    align-items: center;
    display: flex;
    padding: 0 1rem;
  }

  .Player__title {
    font-size: $font-size--medium--mobile;
    font-weight: $font-weight--heavy;
    padding: 0.3rem 0;
  }

  .Player__subtitle {
    font-size: $font-size--small--mobile;
    padding-bottom: 0.3rem;
  }

  .Player__tracklist {
    transition: none;
    transform: none;
    bottom: -15rem;

    &.is-visible {
      bottom: 4rem;
      height: auto;
      max-height: 100%;
      padding-top: 0;
      top: 0;
      transform: none;
      z-index: 7;
    }

    &.is-closing {
      display: none;
    }
  }

  .Player__action__trigger {
    display: block;
    line-height: 4rem;
    font-size: $font-size--small--mobile;
    height: 4rem;
    margin: 0;
    right: 0;
    z-index: 6;

    &:after {
      height: 4rem;
    }
  }

  .Player__waveform-container {
    height: 0;
    position: absolute;
    top: -4rem;
    width: 100%;

    &.is-visible {
      height: 4rem;
    }
  }

  .Player__button {
    display: none;
  }
}

@media screen and (max-width: $screen-xs) {
  .Player__banner-outer {
    display: none;
  }

  .Player__title {
    margin-top: 0.1rem;
  }

  .Player__subtitle {
    font-size: 1.2rem;
    white-space: nowrap;
  }
}
