.PlayerButton {
  background-color: #e26014;
  border-radius: 2.75rem;
  color: #fff;
  display: block;
  flex: 0 0 auto;
  height: 4.4rem;
  position: relative;
  transition:
    opacity 0.2s linear,
    background 0.2s linear;
  width: 4.4rem;

  &:hover {
    background-color: #e6702c;
    cursor: pointer;
  }

  &:focus {
    outline: auto 0 -webkit-focus-ring-color;
  }

  &:after {
    background-image: url('/images/icon-play.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 1.8rem;
    left: 1.3rem;
    position: absolute;
    top: 1.3rem;
    transition: background-color 0.2s ease;
    width: 2rem;
  }

  &.is-disabled {
    background: #666;
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }

  &.display-only {
    cursor: default;
    pointer-events: none;
  }

  // Adding specificity to overide default PlayerButton...
  //  trying to avoid complete rewrite for now
  &.PlayerButton--mini,
  &.PlayerButton--mini.PAUSED,
  &.PlayerButton--mini.PLAYING {
    width: 2rem;
    height: 2rem;

    &:after {
      left: 0.6rem;
      top: 0.5rem;
      height: 1rem;
      width: 1rem;
    }
  }

  &.PlayerButton--mini.PLAYING {
    &:after {
      left: 0.5rem;
    }
  }
}

.PlayerButton.PLAYING {
  background: $color-primary;

  &:after {
    background-image: url('/images/icon-pause.svg');
    height: 1.6rem;
    left: 1.4rem;
    top: 1.4rem;
    width: 1.6rem;
  }
}

.PlayerButton.is-stream.PLAYING {
  &:after {
    background-image: url('/images/icon-stop.svg');
  }
}

.PlayerButton.PAUSED {
  &:after {
    background-image: url('/images/icon-play.svg');
  }
}

//Primary variation used on Proton Radio page
.PlayerButton--youtube {
  background-color: $color-primary;
  height: 3rem;
  width: 3rem;

  &:after {
    left: 1rem;
    top: 0.9rem;
    height: 1.3rem;
    width: 1.3rem;
  }
}

// Gray variation of PlayerButton button used on mix pages.
.PlayerButton--light {
  background-color: $color-gray--light;
  height: 3.4rem;
  width: 3.4rem;

  &:after {
    height: 1.4rem;
    left: 1.1rem;
    top: 1rem;
    width: 1.5rem;
  }

  &.PLAYING:after {
    height: 1.4rem;
    left: 1rem;
    top: 1rem;
    width: 1.4rem;
  }
}

// Small dark play button in top-10 component on homepage
.PlayerButton--dark {
  background-color: rgba(255, 255, 255, 0.2);
  height: 2.6rem;
  width: 2.6rem;

  &:after {
    left: 0.9rem;
    top: 0.8rem;
    height: 1rem;
    width: 1rem;
  }
}

.PlayerButton--dark.PLAYING {
  &:after {
    left: 0.8rem;
    top: 0.8rem;
    height: 1rem;
    width: 1rem;
  }
}

@media screen and (max-width: $screen-sm) {
  .PlayerButton {
    height: 3.2rem;
    width: 3.2rem;

    &:after {
      left: 1rem;
      top: 0.9rem;
      height: 1.4rem;
      width: 1.4rem;
    }

    &.PLAYING:after {
      left: 0.9rem;
      top: 0.9rem;
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  .PlayerButton--light {
    height: 2.6rem;
    width: 2.6rem;

    &:after {
      left: 0.9rem;
      top: 0.8rem;
      height: 1rem;
      width: 1rem;
    }

    &.PLAYING:after {
      left: 0.8rem;
      top: 0.8rem;
      height: 1rem;
      width: 1rem;
    }
  }

  .PlayerButton--youtube {
    height: 3rem;
    width: 3rem;

    &:after {
      left: 1rem;
      top: 0.9rem;
      height: 1.3rem;
      width: 1.3rem;
    }
  }
}

.PlayerButton.BUFFERING:after {
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-name: buffering;
}

@keyframes buffering {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.PlayerButton__tombstone {
  &.PlayerButton--light:after {
    content: none;
  }

  &:hover {
    cursor: default;
    background-color: $color-gray--light;
  }
}
