.SideNav__container {
  position: sticky;
  top: 10rem;
  width: 100%;
}

.SideNav__item {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  font-weight: 500;
  justify-content: space-between;
  line-height: 1;
  margin: 0.5rem 0;
  width: 100%;

  &:hover {
    background: #fafafa;
    color: #333;
    cursor: pointer;
  }

  &:first-child {
    margin-top: 0;
  }

  a {
    align-items: center;
    border-radius: 4px;
    color: #777;
    display: flex;
    padding: 1rem;
    width: 100%;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.active {
      background: $color-primary;
      color: #fff;
      position: relative;
    }
  }

  &.SideNav__disabled,
  &.SideNav__disabled a {
    color: #ccc;
    &:hover {
      background: none;
      cursor: not-allowed;
    }
  }
}

.SideNav__item .SideNav__item__text {
  flex: 1 0 auto;
}

// MOBILE
@media screen and (max-width: $screen-sm) {
  .SideNav__container {
    margin: 0;
    padding: 1rem 0;
    position: relative;
    top: 0;
    width: 100%;
  }

  .SideNav__item,
  .SideNav__item a {
    align-items: center;
    justify-content: flex-start;
    display: block;

    a {
      display: flex;
      padding: 1rem;
    }

    &.active {
      &:after {
        display: none;
      }
    }
  }

  .SideNav__item__badge {
    margin-left: 1rem;
  }

  .SideNav__item__text {
    display: inline;
    flex: 0 0 auto;
    margin-right: 0.5rem;
  }
}
