.i {
  display: inline-block;
  width: 0.9em;
  height: 0.9em;
  margin-right: 0.4em;
  position: relative;
  top: 0.12em;
  user-select: none;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

button .i {
  top: 0;
}

.i-collection {
  background-image: url('/images/icon-mixes.svg');
}

.i-radio {
  background-image: url('/images/icon-radio.svg');
}

.i-inbox {
  background-image: url('/images/icon-radio.svg');
}

.i-spotify {
  background-image: url('/images/icon-spotify-white.svg');
}

.i-sign-out {
  background-image: url('/images/icon-signout.svg');
}

.i-user {
  background-image: url('/images/icon-user.svg');
}

.i-mixes {
  background-image: url('/images/icon-mixes.svg');
}

.i-label {
  background-image: url('/images/icon-label-white.svg');
}

.i-inbox {
  background-image: url('/images/icon-inbox.svg');
}
