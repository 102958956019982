// Override Bootstrap 3s smaller 480px tiny breakpoint in favor
// of Bootstrap 4s to bring it in sync with our config/constants
$screen-xs: 576px;

$color-primary: #e26014;
$color-primary--hover: lighten($color-primary, 4%);
$color-primary--active: darken($color-primary, 4%);

$color-gray--superlight: #f7f8f9;
$color-gray--light: #ddd;
$color-gray--medium: #7d7d7d;
$color-gray: #7d7d7d;
$color-gray--dark: #4d4d4d;
$color-gray--superdark: #141211;

$color-gray--dark--hover: lighten($color-gray--dark, 8%);
$color-gray--superdark--hover: lighten($color-gray--superdark, 8%);

$color-links: $color-primary;

$color-error: #e33939;
$color-danger: $color-error;

$color-safety: #e7b223;

//Legacy Colors
$color-lightGray: $color-gray--light;

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--heavy: 600;

$font-size--xsmall: 1.2rem;
$font-size--small: 1.4rem;
$font-size--medium: 1.8rem;
$font-size--large: 2.2rem;
$font-size--xlarge: 3.4rem;
$font-size--xxlarge: 4.2rem;
$font-size--jumbo: 6rem;

$font-size--xsmall--mobile: 1rem;
$font-size--small--mobile: 1.2rem;
$font-size--medium--mobile: 1.4rem;
$font-size--large--mobile: 1.8rem;
$font-size--xlarge--mobile: 2.4rem;

$margin-default: 30px;

$header-height: 5.4rem;
$header-height--mobile: 4.4rem;
$player-height: 6rem;
$player-height--mobile: 4rem;
$player-height--mobile-with-waveform: $player-height--mobile + 4rem;

// Bootstrap Overrides - Reference http://getbootstrap.com/customize/ for existing variables
// and override here to minimize the amount of actual CSS rules you need to override.
$tooltip-arrow-color: $color-gray;
$tooltip-bg: $color-gray;

$brand-primary: $color-primary;
$border-radius-base: 0;
$border-radius-small: 0;
$border-radius-large: 0;
