.TextHeader {
  text-align: left;
  color: #141211;

  a {
    color: #141211;
  }
}

.TextHeader--color--primary {
  color: $color-primary;

  a {
    color: $color-primary;
  }
}

.TextHeader--color--secondary {
  color: #999;

  a {
    color: #999;
  }
}

.TextHeader--color--white {
  color: white;

  a {
    color: white;
  }
}

.TextHeader--colorLinks a {
  color: $color-primary;

  &:hover {
    text-decoration: underline;
  }
}

a:hover .TextHeader {
  color: $color-primary;
}

.TextHeader--jumbo {
  font-size: 6.4rem;
  margin-bottom: 0;
  margin-left: -0.3rem; // Akagi is really bad about alignment at larger sizes, so manually adjust
  line-height: 1;

  @media screen and (max-width: $screen-md) {
    font-size: 5.4rem;
  }
}

.TextHeader--xlarge {
  font-size: $font-size--xxlarge;
  line-height: 1.2;

  @media screen and (max-width: $screen-md) {
    font-size: $font-size--xlarge;
  }
}

.TextHeader--large {
  font-size: $font-size--large;
  line-height: 1.2;
}

.TextHeader--large + .TextHeader--large {
  line-height: 1;

  &:last-child {
    padding-bottom: 0.1rem;
  }
}

.TextHeader--medium {
  font-size: $font-size--medium;
  font-weight: $font-weight--heavy;
  line-height: 1.4;
}

.TextHeader--small {
  font-size: $font-size--small;
  font-weight: $font-weight--heavy;
  line-height: 1.4;
}

.TextHeader--xsmall {
  color: #999;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.TextHeader--weight--light {
  font-weight: 300;
}

.TextHeader--weight--normal {
  font-weight: 500;
}

.TextHeader--weight--heavy {
  font-weight: 600;
}

.TextHeader--color--light {
  color: #ffffff;

  a {
    color: #ffffff;
    &:hover {
      text-decoration: underline;
    }
  }
}

.TextHeader--ellipsis-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TextHeader--uppercase {
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.TextHeader--underline {
  border-bottom: 1px dotted #ccc;
}
