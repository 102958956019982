@mixin searchInputFont {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 300;
}

@mixin searchInputBackground($imageUrl) {
  background: url($imageUrl) no-repeat scroll 0.6rem 0.7rem;
  background-size: 1.8rem;
}

@mixin searchClearBackground($imageUrl) {
  background: url($imageUrl) no-repeat scroll 1rem 1.3rem;
  background-size: 1.1rem 1.1rem;
}

.UniversalSearchInput__container {
  width: 100%;
  position: relative;
}

.UniversalSearchInput__input-wrapper {
  position: relative;
}

.UniversalSearchInput__clear {
  position: absolute;
  right: 0;
  top: 0;
  width: 3.1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include searchClearBackground('/images/icon-input-clear.svg');

  &:focus {
    border: none;
  }
}

.UniversalSearchInput {
  @include searchInputBackground('/images/icon-search.svg');
  padding-left: 3.2rem;
  width: 100%;
  height: 3.5rem;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  @include searchInputFont;
  color: #fff;

  &::placeholder {
    @include searchInputFont;
    color: rgba(255, 255, 255, 0.3);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &:focus,
  &.show-results {
    outline: none;
  }
}

// desktop mode only
@media screen and (min-width: $screen-xs) {
  .UniversalSearchInput {
    &.show-results {
      @include searchInputBackground('/images/icon-search-dark.svg');
      border-color: #eeeeee;
      background-color: #fff;
      color: #aaaaaa;

      &::placeholder {
        color: #aaaaaa;
      }
    }
  }

  .UniversalSearchInput__clear {
    &:not(.on-search-page) {
      @include searchClearBackground('/images/icon-input-clear-dark.svg');
    }
  }
}

.Search__results {
  background: white;
  padding-bottom: 4rem;
}

// Desktop
@media screen and (min-width: $screen-xs) {
  .Search__results {
    display: none;

    &.show-results {
      position: absolute;
      display: flex;
      left: 0;
      right: 0;
      min-height: 10rem;
      height: 80vh;
      max-height: 50rem;
      border: solid 1px #eeeeee;
      border-top: 0;
      padding-bottom: 4rem;
    }
  }
}

// Mobile
@media screen and (max-width: $screen-xs) {
  .Search__results {
    position: fixed;
    margin-top: $header-height--mobile;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
  }
}
