/* Webfonts */
/*
@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-el-webfont.eot");
  src: url("/webfonts/akagipro-el-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-el-webfont.woff") format("woff"), url("/webfonts/akagipro-el-webfont.ttf") format("truetype"), url("/webfonts/akagipro-el-webfont.svg#akagi_pro_extra_lightregular") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-elit-webfont.eot");
  src: url("/webfonts/akagipro-elit-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-elit-webfont.woff") format("woff"), url("/webfonts/akagipro-elit-webfont.ttf") format("truetype"), url("/webfonts/akagipro-elit-webfont.svg#akagi_pro_extra_light_italiRg") format("svg");
  font-weight: 200;
  font-style: italic;
}*/

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-lt-webfont.eot");
  src: url("/webfonts/akagipro-lt-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-lt-webfont.woff") format("woff"), url("/webfonts/akagipro-lt-webfont.ttf") format("truetype"), url("/webfonts/akagipro-lt-webfont.svg#akagi_pro_lightregular") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-ltit-webfont.eot");
  src: url("/webfonts/akagipro-ltit-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-ltit-webfont.woff") format("woff"), url("/webfonts/akagipro-ltit-webfont.ttf") format("truetype"), url("/webfonts/akagipro-ltit-webfont.svg#akagi_pro_light_italicregular") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-bk-webfont.eot");
  src: url("/webfonts/akagipro-bk-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-bk-webfont.woff") format("woff"), url("/webfonts/akagipro-bk-webfont.ttf") format("truetype"), url("/webfonts/akagipro-bk-webfont.svg#akagi_pro_bookregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-bkit-webfont.eot");
  src: url("/webfonts/akagipro-bkit-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-bkit-webfont.woff") format("woff"), url("/webfonts/akagipro-bkit-webfont.ttf") format("truetype"), url("/webfonts/akagipro-bkit-webfont.svg#akagi_pro_book_italicregular") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-md-webfont.eot");
  src: url("/webfonts/akagipro-md-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-md-webfont.woff") format("woff"), url("/webfonts/akagipro-md-webfont.ttf") format("truetype"), url("/webfonts/akagipro-md-webfont.svg#akagi_pro_mediumregular") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-mdit-webfont.eot");
  src: url("/webfonts/akagipro-mdit-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-mdit-webfont.woff") format("woff"), url("/webfonts/akagipro-mdit-webfont.ttf") format("truetype"), url("/webfonts/akagipro-mdit-webfont.svg#akagi_pro_medium_italicRg") format("svg");
  font-weight: 500;
  font-style: italic;
}
/*
@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-sm-webfont.eot");
  src: url("/webfonts/akagipro-sm-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-sm-webfont.woff") format("woff"), url("/webfonts/akagipro-sm-webfont.ttf") format("truetype"), url("/webfonts/akagipro-sm-webfont.svg#akagi_pro_semiboldregular") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-smit-webfont.eot");
  src: url("/webfonts/akagipro-smit-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-smit-webfont.woff") format("woff"), url("/webfonts/akagipro-smit-webfont.ttf") format("truetype"), url("/webfonts/akagipro-smit-webfont.svg#akagi_pro_semibold_italicRg") format("svg");
  font-weight: 600;
  font-style: italic;
}
*/
@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-bd-webfont.eot");
  src: url("/webfonts/akagipro-bd-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-bd-webfont.woff") format("woff"), url("/webfonts/akagipro-bd-webfont.ttf") format("truetype"), url("/webfonts/akagipro-bd-webfont.svg#akagi_pro_boldregular") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-bdit-webfont.eot");
  src: url("/webfonts/akagipro-bdit-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-bdit-webfont.woff") format("woff"), url("/webfonts/akagipro-bdit-webfont.ttf") format("truetype"), url("/webfonts/akagipro-bdit-webfont.svg#akagi_pro_bold_italicregular") format("svg");
  font-weight: 700;
  font-style: italic;
}
/*
@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-eb-webfont.eot");
  src: url("/webfonts/akagipro-eb-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-eb-webfont.woff") format("woff"), url("/webfonts/akagipro-eb-webfont.ttf") format("truetype"), url("/webfonts/akagipro-eb-webfont.svg#akagi_pro_extra_boldregular") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Akagi Pro';
  src: url("/webfonts/akagipro-ebit-webfont.eot");
  src: url("/webfonts/akagipro-ebit-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/akagipro-ebit-webfont.woff") format("woff"), url("/webfonts/akagipro-ebit-webfont.ttf") format("truetype"), url("/webfonts/akagipro-ebit-webfont.svg#akagi_pro_extra_bold_italicRg") format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'modern_pictogramsnormal';
  src: url("/webfonts/modernpics-webfont.eot");
  src: url("/webfonts/modernpics-webfont.eot?#iefix") format("embedded-opentype"), url("/webfonts/modernpics-webfont.woff") format("woff"), url("/webfonts/modernpics-webfont.ttf") format("truetype"), url("/webfonts/modernpics-webfont.svg#akagi_pro_fat_italicregular") format("svg");
  font-weight: normal;
  font-style: normal;
}*/