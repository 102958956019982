$active-action-background: #fafafa;

.FlatListItem {
  align-items: center;
  display: flex;
  margin: 0 -1rem;
  padding: 0 1rem;

  &.active,
  &:hover {
    background: $active-action-background;

    .Dropdown__toggle {
      img {
        opacity: 0.9;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }

  &.display-only {
    &:hover {
      cursor: default;
      background: none;
    }
  }

  &:focus {
    outline: none;
  }

  &.is-disabled {
    background: inherit;
    opacity: 0.3;
  }

  &.loading {
    background: inherit;
    &:hover {
      background: none;
    }
  }
}

.FlatListItem__content {
  flex: 1;

  .ListItem {
    padding-left: 0;
  }
}

.FlatListItem__aside {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  margin-left: 1.5rem;
  opacity: 1;
  padding: 0;
  transition: opacity 0.2s linear;

  .Button {
    margin-right: 1rem;
    opacity: 0.15;
    transition: opacity 0.1s linear;

    &.EllipsisButton,
    &:last-child {
      margin-right: 0;
    }
  }
}

.is-disabled {
  .FlatListItem__aside {
    pointer-events: none;
    user-select: none;
    white-space: nowrap;
    
    .Button {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

// *** MOBILE styling ***
@media screen and (max-width: $screen-xs) {
  .FlatListItem {
    &.is-disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

// *** DESKTOP styling ***
@media screen and (min-width: $screen-xs) {
  .FlatListItem {
    // highlight if hovering on desktop but no cursor if dropdown available
    &:hover {
      background: $active-action-background;
      cursor: default;

      .FlatListItem__aside .Button {
        opacity: 1;
      }

      &.is-clickable {
        cursor: pointer;
      }
    }
  }

  .FlatListItem.is-disabled {
    .FlatListItem__aside .Button {
      opacity: 0.15;
    }
  }
}

// *** MOBILE styling ***
// only disable hover background class on touch devices
.touch-enabled {
  .FlatListItem {
    &:hover {
      background: none;
    }
  }
}
