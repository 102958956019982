.banner-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}

.banner-grid__item {
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 33.33%;
  overflow: hidden;

  img {
    max-width: 100%;
  }
}

.banner-grid__item--primary .banner-grid__link {
  font-weight: $font-weight--heavy;
}

.banner-grid__link {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: $font-size--xlarge;
  font-weight: 300;
  height: 12rem;
  justify-content: center;
  margin: 0.5rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;

  &:after {
    background: rgba(0, 0, 0, 0.1);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    transition: background 0.25s linear;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;

    &:after {
      background: rgba(0, 0, 0, 0);
    }
  }
}

@media screen and (max-width: $screen-md) {
  .banner-grid__item {
    order: 1;
    width: 50%;
  }

  .banner-grid__item--primary {
    order: -1;
    width: 100%;
  }

  .banner-grid__link {
    font-size: $font-size--large;
    height: 10rem;
  }
}

@media screen and (max-width: $screen-sm) {
  .banner-grid__link {
    height: 8rem;
  }
}
