@keyframes animation-shimmering {
  0% {
    background-position: -300px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

// Using !important rules for now to make sure they take
// priority over other background-color rules in styled-components
// regardless of the order in which they get applied.
.TombstoneAnimation {
  animation: animation-shimmering 1.5s linear infinite;
  background-position: -300px 0;
  background-repeat: no-repeat;
  background-size: 300px 100%;
  background-color: #f6f8f9 !important;
}

.TombstoneAnimationDark {
  background-image: linear-gradient(
    -90deg,
    #444 0%,
    darken(#444, 10%) 50%,
    #444 100%
  );
  background-color: #444 !important;
}

.TombstoneAnimationLight {
  background-image: linear-gradient(
    -90deg,
    #d1d1d1 0%,
    darken(#d1d1d1, 10%) 50%,
    #d1d1d1 100%
  );
  background-color: #d1d1d1 !important;
}

.Tombstone__avatar {
  @extend .TombstoneAnimation;
  height: 4rem;
  margin: 0 1rem 0 0;
  width: 4rem;
}

.Tombstone {
  border-radius: 1px;
  @extend .TombstoneAnimation;

  &.Tombstone--grow {
    width: 100%;
    max-width: none;
  }
}

.Tombstone--dark {
  @extend .TombstoneAnimationDark;
}

.Tombstone--light {
  @extend .TombstoneAnimationLight;
}

.Tombstone__avatar--rounded {
  border-radius: 50rem;
}

.TombstoneText--xsmall {
  height: 1rem;
  max-width: 14rem;
}

.TombstoneText--small {
  height: 1.4rem;
  max-width: 18rem;
}

.TombstoneText--medium {
  height: 1.8rem;
  max-width: 22rem;
}

.TombstoneText--large {
  height: 2.2rem;
  max-width: 28rem;
}

.TombstoneText--xlarge {
  height: 2.8rem;
  max-width: 36rem;
}

.TombstoneText--EntityHeader-title {
  height: 4rem;
  width: 15rem;
}

.Tombstone__small {
  height: 1.4rem;
}

.Tombstone__title,
.Tombstone__medium {
  @extend .TombstoneAnimation;
  height: 1.5rem;
  margin: 0.5rem 0;
  width: 20rem;
}

.Tombstone__large {
  height: 2rem;
}

.Tombstone__text {
  @extend .TombstoneAnimation;
  height: 0.8rem;
  margin: 0.5rem 0;
  width: 12rem;
}

@media screen and (min-width: $screen-sm) {
  .Tombstone__avatar {
    height: 5rem;
    width: 5rem;
  }

  .Tombstone__avatar--large {
    height: 16rem;
    width: 16rem;
  }
}
