.Top-mixes-tile {
  height: 100%;
  margin: 0 auto 4rem auto;
  max-width: 57.8rem;
  min-height: 20rem;
  overflow: hidden;
}

.Top-mixes-tile__content {
  background: rgba(0, 0, 0, 0.65);
  display: block;
  height: 100%;
  min-height: 200px;
  width: 100%;
}

.Top-mixes-tile__footer {
  background: rgba(0, 0, 0, 0.65);
  padding: 1rem 0;
  text-align: center;

  a {
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
}

.Tile__inner__header {
  align-items: flex-end;
  display: flex;
}

.Tile__inner__header__title {
  flex: 1 0 auto;
}

.Tile__inner__header__aside {
  color: #aaaaaa;
  flex: 0 1 auto;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15em;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
