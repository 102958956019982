.FlatList--divided {
  li {
    align-items: center;
    border-bottom: 1px solid #eee;
    display: flex;
    min-height: 4rem;
    padding: 1rem 0;

    &:first-child {
      border-top: 1px solid #eee;
    }
  }
}

.FlatList--divided-inner {
  li {
    align-items: center;
    border-top: 1px solid #eee;
    display: flex;
    min-height: 4rem;
    padding: 0.5rem 0;

    &:first-child {
      border-top: none;
    }
  }
}
