.Table {
  color: #999;

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: $color-primary;
      text-decoration: none;
    }
  }

  .ReactVirtualized__Grid {
    outline: none !important;
  }

  .ReactVirtualized__Table__row {
    border-radius: 2px;
    outline: none;

    .show-on-hover {
      opacity: 0;
    }

    .show-on-hover.is-open {
      opacity: 1;
    }

    .show-on-hover.is-open.disabled {
      opacity: 0.7;
    }

    &.selected,
    &:hover {
      background: #fafafa;
      color: #666;

      .show-on-hover {
        opacity: 1;
      }

      .show-on-hover.disabled {
        opacity: 0.7;
      }
    }

    &.disabled {
      &:before {
        // overlay content for disabled look
        background: rgba(255, 255, 255, 0.4);
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    // Necessary to adjust for Akagi Pro's terrible vertical alignment.
    .TextHeader--medium {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .Table__row--active {
    background: #fafafa;
  }
}

.ReactVirtualized__Table__headerRow {
  color: #666;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.15em;
  position: relative;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0;
}

.ReactVirtualized__Table__headerColumn:last-child,
.ReactVirtualized__Table__rowColumn:last-child {
  text-align: right;
  padding-right: 0.8rem;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  white-space: nowrap;

  &:hover {
    .ReactVirtualized__Table__headerTruncatedText {
      color: #333;
    }

    .ReactVirtualized__Table__sortableHeaderIcon,
    .ReactVirtualized__Table__sortableHeaderIcon.is-disabled {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
}

.ReactVirtualized__Table__sortableHeaderIcon {
  background: #eee;
  border-radius: 1px;
  height: 1.5rem;
  margin-left: 0.5rem;
  margin-top: -0.4rem;
  width: 1.5rem;

  &.is-disabled {
    opacity: 0.3;
  }
}

.ReactVirtualized__Table__sortableHeaderIcon--ASC,
.ReactVirtualized__Table__sortableHeaderIcon--DESC {
  background: $color-gray--light;
  color: $color-gray--dark;
}

.ReactVirtualized__Grid__innerScrollContainer div[role='button'] {
  outline: none;
}

@media screen and (min-width: $screen-sm) {
  .Table {
    margin-top: 1rem;
  }

  .ReactVirtualized__Table__headerRow,
  .ReactVirtualized__Table__row {
    &:after {
      background-color: #f0f0f0;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 3rem;
      right: 0;
      position: absolute;
    }
  }

  .ReactVirtualized__Table__headerRow {
    &:after {
      left: 0;
    }
  }

  .ReactVirtualized__Table__row {
    &:after {
      // hairline width hack
      background: linear-gradient(
        transparent 0%,
        transparent 50%,
        #f0f0f0 50%,
        #f0f0f0 100%
      );
    }
  }

  // Hide default table row border if expanded
  .Table__row--expanded {
    .ReactVirtualized__Table__row::after {
      content: none;
    }
  }
}

// MOBILE size
@media screen and (max-width: $screen-sm) {
  .Table {
    .hide-mobile {
      display: none;
    }
  }

  .ReactVirtualized__Table__row:hover {
    // Show clickable when small desktop view
    cursor: pointer;
  }
}

.Table__toggle {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    flex: 1;
    text-align: center;
    outline: none;
  }
}

.Table__footer-message {
  font-size: 2rem;
  margin: 6rem 1rem 9rem;
  text-align: center;
}

.touch-enabled {
  .Table {
    .ReactVirtualized__Table__row:hover {
      // remove hover background on mobile device (has touch)
      background: none;
    }
  }
}

.Table__empty {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 20rem;
  text-align: center;
}
