.UserNav {
  height: 100%;
  margin-left: 0.5rem;
  position: relative;

  .UserNav__end_masquerade.nav__list__item {
    color: $color-danger;
    &:hover {
      color: #ffffff;
    }
  }
}

.ButtonGroup.UserNav__button-group--desktop {
  padding: 1rem 0;
}

.ButtonGroup.UserNav__button-group--mobile {
  margin: 0 1.5rem;
}

.UserNav__user {
  color: #fff;
  line-height: 1.6;
  padding-left: 1.5rem;
}

.UserNav__user__logged-in {
  font-size: $font-size--xsmall--mobile;
  font-weight: $font-weight--heavy;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.UserNav__user__username {
  font-size: 1.8rem;
  margin-bottom: 0;
}

.UserNav__header {
  color: #eee;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 1.5rem 1.5rem 0.25rem 1.5rem;
}

a.UserNav__header {
  color: #ccc;
  cursor: pointer;
  height: auto;
  line-height: 1;
  font-size: 1.1rem;

  &:hover {
    background: none;
    color: #fff;
  }
}

@media screen and (max-width: $screen-md) {
  .UserNav {
    margin: 0;
    padding: 1rem 0;

    .Button {
      margin: 0.5rem 0 0 0;
    }
  }
}
