.container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.6);
  display: none;
}

.container-fluid {
  overflow: hidden;
}

.container--small {
  max-width: 400px;
  margin: 0 auto;
}

.container--medium {
  max-width: 800px;
  margin: 0 auto;
}

@media screen and (max-width: $screen-md) {
  .container-fluid {
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: $screen-sm) {
  .container-fluid {
    padding: 0 1rem;
  }

  .row {
    margin: 0 -1.5rem;
  }
}

@media screen and (max-width: $screen-xs) {
  .container--small {
    max-width: none;
  }
}

.Wrapper {
  margin: 0 1rem;
}

.flex-grow {
  flex-grow: 1;
}
