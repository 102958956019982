.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list--simple {
  li {
    font-size: 1.8rem;
    margin: 1rem 0;
  }

  a {
    color: $color-gray;

    &:hover,
    &:focus,
    &:active {
      color: $color-links;
    }
  }
}