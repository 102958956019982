.TracksTable {
  .ReactVirtualized__Table__row {
    &:after {
      content: none;
    }
  }
  outline: none;
}

.TrackTable__title {
  display: flex;
}

.TrackTable__title__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  padding-left: 1rem;

  a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.TrackTable__title__text--multiline {
  margin-top: -0.2rem;
}

.TrackTable__subtitle__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TracksTable__mobile__placeholder {
  left: 0;
  margin: auto;
  padding-top: 10rem;
  position: absolute;
  right: 0;
  text-align: center;
}
