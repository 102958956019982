.UserSearchResult {
  display: flex;
  outline: none;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .UniversalSearchResultRow__info {
    padding-left: 0;
  }
}

.UserSearchResult__user__section {
  flex: 1 1 0;
  padding-right: 0.5rem;
  width: 10rem;
}

.UserSearchResult__user__section--wide {
  width: 30rem;
}

.UserSearchResult__actions--trigger-enter {
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 10rem;
}

.UserSearchResult__actions {
  align-items: center;
  background: #f9f9f9;
  bottom: 0;
  display: flex;
  padding: 0 1.5rem;
  position: absolute;
  top: 0;

  &:hover {
    cursor: default;
  }
}

// Desktop
@media screen and (min-width: $screen-xs) {
  .UserSearchResult {
    .UniversalSearchResultRow__info {
      flex: 0;
      padding-left: 0;
    }
  }
}

// Mobile
@media screen and (max-width: $screen-xs) {
  .UserSearchResult__user__section {
    width: auto;
  }
}
