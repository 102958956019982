.AddSubscriber {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .container-fluid {
    width: 100%;
  }
}

.AddSubscriber__Back {
  display: block;
  margin-bottom: 1rem;
  margin-top: 2rem;

  img {
    height: 1.1rem;
    margin-right: 0.5rem;
    margin-bottom: -0.1rem;
  }
}

.AddSubscriber__header {
  padding-top: 1rem;
}

.AddSubscriber__list_container {
  margin-top: 2rem;
  min-height: 10rem;
  flex: 1 0 auto;
}

@media screen and (min-width: $screen-xs) {
  .AddSubscriber__header {
    padding-top: 2rem;
  }
}
