.UniversalSearchResults__container {
  background: #fff;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;

  .UniversalSearchResults__get-started {
    align-items: center;
    background: #f9f9f9;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 1.5rem 1.5rem;
    width: 100%;
  }

  .UniversalSearchResults__get-started__inner {
    align-items: center;
    display: flex;
  }

  .UniversalSearchResults__get-started__avatar {
    background: #fff;
    height: 10rem;
    margin: 0 1.5rem 0 0;
    padding: 0;
    width: 10rem;

    img {
      width: 10rem;
    }
  }
}

.UniversalSearchResults__results {
  flex: 1;
  overflow-y: auto;
}

.UniversalSearchResults__get-started {
  align-items: center;
  display: flex;
  text-align: center;

  div {
    font-size: 2.5rem;
    font-weight: 300;
  }
}

.UniversalSearchResults__get-started__avatar {
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 10rem;
  height: 20rem;
  margin: 0 auto 1.5rem auto;
  overflow: hidden;
  text-align: center;
  width: 20rem;

  img {
    width: 20rem;
  }
}

.UniversalSearchResults__results__label {
  padding: 1rem;
  display: flex;
  align-items: center;

  .result__label {
    font-size: 1.8rem;
    font-weight: bold;
    color: #000;
    margin-right: 1rem;
  }

  .NumberBadge {
    margin-left: 1rem;
  }
}

.UniversalSearchResults__results__content.genres {
  max-width: 57rem;
  padding: 1rem 0.5rem;
}

.UniversalSearchResults__show-more {
  font-size: 1.2rem;
  padding: 1rem;
  color: $color-gray;

  &:hover {
    text-decoration: underline;
  }
}

.UniversalSearchResults__see-all {
  font-size: 1.6rem;
  color: $brand-primary;
  background-color: white;
  height: 4rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dddddd;

  &:hover {
    cursor: pointer;
    font-weight: 500;
    background-color: #fafafa;
  }
}

// result display from app header (not '/search' results page)
.Header__nav__search {
  .section.section--small {
    margin-bottom: 1rem;
  }

  .UniversalSearchResults__results {
    height: 100%;
  }

  .UniversalSearchResults__container {
    .UniversalSearchResults__get-started {
      background: none;

      .UniversalSearchResults__get-started__inner {
        flex-direction: column;
      }

      .UniversalSearchResults__get-started__avatar {
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .UniversalSearchResults__container {
    align-items: center;
    background: none;

    .UniversalSearchResults__get-started {
      background: none;

      div {
        font-size: 2.1rem;
      }

      .UniversalSearchResults__get-started__inner {
        flex-direction: column;
      }

      .UniversalSearchResults__get-started__avatar {
        margin-bottom: 1rem;
      }
    }
  }
}

// mobile
// - display "What are you looking for?" with keyboard visible
@media screen and (max-width: $screen-xs) {
  .UniversalSearchResults__container {
    align-items: flex-start;

    .UniversalSearchResults__get-started {
      padding-top: 1rem;
    }
  }

  // ONLY for search results page (shrink Icon/padding more
  .SearchResults__results {
    .UniversalSearchResults__get-started {
      .UniversalSearchResults__get-started__avatar {
        margin-bottom: 0.3rem;
        height: 9rem;
        width: 9rem;

        img {
          width: 9rem;
        }
      }
    }
  }
}
