.intercom-lightweight-app-launcher {
  right: 10px !important;

  @media screen and (max-width: 450px) {
    bottom: 45px !important;
  }
}

.intercom-lightweight-app-launcher.intercom-launcher {
  transition:
    transform 100ms linear,
    opacity 80ms linear,
    bottom 250ms ease-in-out;
}

.intercom-lightweight-app.waveform-visible
  .intercom-lightweight-app-launcher.intercom-launcher {
  @media screen and (max-width: 992px) {
    bottom: 90px !important;
  }
}
