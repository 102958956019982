.media {
  display: flex;
  align-items: flex-start;
  overflow: visible;
  margin-top: 0;
  margin-bottom: 1rem;
}

.media-figure {
  margin-right: 1em;
  
  img {
    max-width: 100%;
  }
}

.media-body {
  flex: 1;
  overflow: visible;
}

.media--small {
  .media-figure {
    max-width: 5.8rem;
    min-width: 5.8rem;
  }

  .form-group:last-child {
  	margin-bottom: 0;
  }
}

.media-body__label {
  margin: 0.5rem 0 0 0;
  font-size: $font-size--medium;
  font-weight: $font-weight--regular;
  color: $color-gray;
}

.media-body__trigger {
  color: $color-gray;
  font-weight: 700;
  font-size: $font-size--large;
  display: inline-block;
  margin-top: 0.5rem;
}

@media screen and (max-width: $screen-xs) {
  .media--small {
    .media-figure {
      height: 5.3rem;
      width: 5.3rem;
      min-width: 5.3rem;
    }
  }
}