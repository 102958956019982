.ActionMenuItem {
  color: $color-gray--dark;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
  margin: 0;
  padding: 0.75rem 1.5rem;
  position: relative;

  &.is-disabled {
    opacity: 0.7;
    &:hover {
      cursor: not-allowed;
    }
  }

  &.has-border {
    border: solid 1px #ccc;

    &:last-child {
      border-top: none;
    }
  }

  &.is-active {
    &:before {
      background: $color-primary;
      content: '';
      bottom: 1rem;
      left: 0;
      position: absolute;
      top: 1rem;
      width: 0.5rem;
      border-radius: 0.2rem;;
    }
  }

  &.is-danger {
    color: $color-danger;
    &:hover {
      color: $color-danger;
    }
  }

  & > a {
    color: $color-gray--dark;
    display: flex;
  }

  // link colors were being changed to orange on hover
  &:hover,
  &:focus {
    background: $color-gray--superlight;
    color: $color-gray--dark;
    text-decoration: none;
  }

  &:first-child {
    margin-top: 1rem;
  }
}

.ActionMenuItem--light {
  color: #ccc;
}

.ActionMenuItem__icon {
  align-items: center;
  display: flex;
  flex: 0 0 3rem;
  height: 3rem;
  justify-content: center;
  margin-right: 1rem;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.ActionMenuItem__title {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  min-height: 3rem;
  margin-right: 1rem;
  white-space: nowrap;
}
