.FlatListHeader {
  display: flex;
  margin: 2rem 0 0 0;
}

.FlatListHeader__sections {
  display: flex;
  flex: 1 1 auto;
}

.FlatListHeader__item {
  border-bottom: 1px solid #eee;
  box-sizing: content-box;
  flex: 1 1 auto;
  padding-bottom: 0.5rem;
  padding-right: 1.5rem;
  width: 15rem;

  &:first-child {
    flex: 0 0 auto;
    width: 30rem;
  }

  @media screen and (max-width: $screen-md) {
    width: 20rem;

    &:first-child {
      width: 20rem;
    }
  }
}
