.Header__wrapper {
  position: relative;
}

// Used to hide input image
.Header__overlay {
  background-color: $color-primary;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.Header__logo a {
  display: block;
  padding: 0.9rem 0;
  position: relative;

  &:hover {
    background: none;
  }
}

.Header {
  background: $color-primary;
  display: flex;
  height: $header-height;
  left: 0;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  // NOTE: z-index is set via styled component in AppHeader.js

  a:hover {
    text-decoration: none;
  }

  &.Header--warning {
    background-color: $color-danger;
    .Header__overlay {
      background-color: $color-danger;
    }

    .nav__list .nav__list__item {
      &.is-active,
      &:hover {
        background: lighten($color-danger, 10%);
      }
    }
  }

  &.Header--safety {
    background-color: $color-safety;
    .Header__overlay {
      background-color: $color-safety;
    }

    .nav__list .nav__list__item {
      &.is-active,
      &:hover {
        background: lighten($color-safety, 10%);
      }
    }
  }

  &.Header--warning.Header--safety {
    background: linear-gradient(0.25turn, $color-safety, $color-danger);;
    .Header__overlay {
      background: linear-gradient(0.25turn, $color-safety, $color-danger);
    }

    .nav__list .nav__list__item {
      &.is-active,
      &:hover {
        background: lighten($color-primary, 10%);
      }
    }
  }
}

.Header__nav {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  z-index: 7;

  @media screen and (max-width: $screen-md) {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.Header__nav__left {
  display: flex;
  flex: 1 0 auto;
  margin-left: 2rem;
}

.Header__nav__right {
  flex: 0 1 auto;
}

.nav__list {
  display: flex;
}

.menu__trigger {
  cursor: pointer;
}

.nav__list span.nav__list__item,
.nav__list a,
.nav__list div.nav__list__item,
.nav__list button {
  color: #fff;
  font-size: $font-size--medium;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background: $color-primary--hover;
    margin-left: 0;
    padding-left: 1.5rem;
  }
}

.nav__dropdown {
  background: $color-gray--superdark;
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;

  button,
  div.nav__list__item,
  a {
    height: 4rem;
    line-height: 4rem;

    &:hover {
      background: $color-gray--superdark--hover;
    }
  }

  li[role='button'] {
    outline: none;
  }
}

.nav__list__item {
  display: block;
  font-weight: $font-weight--light;
  height: $header-height;
  line-height: $header-height;
  padding: 0 1.5rem;

  &.is-active {
    background: $color-primary--hover;
  }
}

.menu__trigger:after {
  background: url('/images/icon-profile.svg') no-repeat center center;
  background-size: 1.6rem;
  content: '';
  display: inline-block;
  margin: 0.5rem 0 0 0.5rem;
  width: 1.6rem;
  height: 1.6rem;
  position: relative;
  top: 0.2rem;
}

.menu__background {
  background: rgba(255, 255, 255, 0.7);
  content: '';
  display: block;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  z-index: 1;
}

// Lock the viewport scrolling and add a translucent overlay
// behind the menu when it's opened.
.Header.menu-open {
  .nav__dropdown {
    height: auto;
    min-width: 20rem;
    right: 0;
    top: 100%;
    width: 100%;
  }

  .menu__background {
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    width: 100%;
  }
}

.content-main {
  min-height: 100vh;
  padding-top: $header-height;
  padding-bottom: 8rem;
  transition: padding-bottom 0.25s ease-in-out; // Matching the tracklist open transition rate.
}

.Nav__section {
  border-bottom: 1px solid #333;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

// Override Button & ButtonGroup defaults
button.Button.nav__list__item {
  border-radius: 0;
}

// Mobile Only Styles
@media screen and (max-width: $screen-md) {
  .Header {
    height: $header-height--mobile;
    padding: 0 1rem;
  }

  .Header__logo {
    flex: 1 0 auto;
    padding: 0.7rem 0;
    z-index: 1;

    a {
      float: left;
      padding: 0;
    }

    img {
      height: 3rem;
    }
  }

  .Header__nav {
    background: $color-gray--superdark;
    flex-direction: column;
    height: 100%;
    min-width: 20rem;
    max-width: 24rem;
    position: fixed;
    right: -24rem;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease-in-out;
    width: 80%;
    z-index: 7;
  }

  .Header__nav__left {
    display: block;
    margin-left: 0;

    li:first-child a {
      border-top: 1px solid lighten($color-gray--superdark, 10%);
      margin-top: 1rem;
    }
  }

  .nav__list {
    display: block;
    margin: 0;
    padding: 0;
  }

  // Override Button & ButtonGroup defaults
  button.Button.nav__list__item {
    &:first-child {
      margin-left: 1.5rem;
    }

    &:hover {
      margin-left: 0;
    }
  }

  .nav__list .nav__list__item {
    background: $color-gray--superdark;
    height: $header-height--mobile;
    line-height: $header-height--mobile;
    margin-left: 1.5rem;
    padding-left: 0;
    position: relative;
    width: 100%;

    &.is-active {
      background: $color-gray--superdark--hover;

      margin-left: 0;
      padding-left: 1.5rem;

      &:before {
        background: lighten($color-gray--superdark, 10%);
        content: '';
        height: 0.1rem;
        left: 0;
        position: absolute;
        top: -0.1rem;
        width: 100%;
      }
    }
  }

  .nav__dropdown {
    height: auto;
    min-width: none;
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
  }

  .menu {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }

  .menu__trigger {
    padding: 0;

    &:after {
      background: url('/images/icon-menu.svg') no-repeat center center;
      background-size: 2.4rem;
      content: '';
      display: inline-block;
      margin: 0;
      width: 4.4rem;
      height: 4.4rem;
      position: relative;
      top: 0;
    }
  }

  .menu__list__item {
    &:after {
      background: rgba(255, 255, 255, 0.25);
      content: '';
      display: block;
      height: 1px;
      left: 1.5rem;
      position: absolute;
      width: 100%;
    }
  }

  .Header.menu-open {
    .Header__nav {
      transform: translate3d(-24rem, 0, 0);
    }

    .menu__background {
      opacity: 1;
    }
  }

  .content-main {
    padding-top: $header-height--mobile;
  }
}

.Header__nav__search {
  width: 37.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
}

@media screen and (max-width: $screen-xs) {
  .Header {
    // hide normal nav-bar when .show-mobile-search present
    &.show-mobile-search {
      padding: 0;

      .Header__mobile_search {
        display: none;
      }

      .Header__logo {
        display: none;
      }

      .Header__nav__search {
        display: flex;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 1;
      }

      .menu__trigger {
        display: none;
      }
    }
  }

  .Header__mobile_search {
    flex: 1;
    z-index: 1;

    .Header__mobile_search--trigger {
      background: url('/images/icon-search.svg') no-repeat scroll 0.6rem 1.2rem;
      background-size: 2rem 2rem;
      // select area
      height: 4.4rem;
      width: 4rem;
      cursor: pointer;
    }
  }

  .Header__logo {
    flex: 0;
  }

  .Header__nav__search {
    position: absolute;
    top: -5px;
    z-index: -1;
  }

  .Header__menu_icon {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    z-index: 1;
  }
}

.search__mobile {
  flex: 1;
  display: flex;
}

.search__mobile__cancel {
  margin-left: 1rem;
  padding-top: 0.1rem;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
}

@media screen and (min-width: $screen-xs) {
  .search__mobile__cancel {
    display: none;
  }
}
