@import './SearchResultItems/UserSearchResult/UserSearchResult';

.UniversalSearchResultRow__container {
  overflow: hidden;

  &.genres__row {
    width: 50%;
    padding: 0 0.5rem;
    display: inline-block;

    &:hover {
      background: inherit;
    }
  }

  &:hover {
    background: #fafafa;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }
}

.UniversalSearchResultRow {
  display: flex;
  padding: 1rem;
  min-height: 4.2rem;

  em {
    background-color: #ff7134;
    border-radius: 0.2rem;
    padding: 0 0.05rem;
    font-style: normal;
    color: #000;
    font-weight: bold;
  }
}

.UniversalSearchResultRow__info {
  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}

// genres:
.UniversalSearchResultRow__tile {
  position: relative;
  text-align: center;

  img {
    width: 100%;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.5s;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2rem;
    width: 100%;
    font-weight: 100;
  }

  em {
    font-style: normal;
  }

  &:hover {
    img {
      opacity: 0.8;
    }
  }
}

.UniversalSearchResultRow__icon-circle {
  background: #fafafa;
  border-radius: 50%;
  border: 1px solid #ddd;
  overflow: hidden;
  width: 5rem;
  height: 5rem;

  img {
    width: 5rem;
    height: 5rem;

    object-fit: cover;
    object-position: center right;
  }
}

.UniversalSearchResultRow__icon-flyer {
  // Need fixed height for images to prevent scroll issues on page load
  height: 5.33rem;
  position: relative;

  img {
    width: 10rem;
    height: 5.33rem;
  }

  &.is-default {
    &:before {
      background-image: url('/images/icon-cassette.svg');
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.98;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.shows__row {
  &:nth-child(5n-4) {
    .UniversalSearchResultRow__icon-flyer {
      &:after {
        background-color: rgba(15, 164, 254, 0.7);
      }
    }
  }
  &:nth-child(5n-3) {
    .UniversalSearchResultRow__icon-flyer {
      &:after {
        background-color: rgba(229, 48, 65, 0.7);
      }
    }
  }
  &:nth-child(5n-2) {
    .UniversalSearchResultRow__icon-flyer {
      &:after {
        background-color: rgba(243, 195, 34, 0.7);
      }
    }
  }
  &:nth-child(5n-1) {
    .UniversalSearchResultRow__icon-flyer {
      &:after {
        background-color: rgba(127, 189, 40, 0.7);
      }
    }
  }
  &:nth-child(5n-0) {
    .UniversalSearchResultRow__icon-flyer {
      &:after {
        background-color: rgba(136, 65, 196, 0.7);
      }
    }
  }
}

.UniversalSearchResultRow__info {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 1.8rem;
  color: #000;
  font-weight: bold;
  padding-left: 1rem;

  .UniversalSearchResultRow__info__title {
    font-size: 1.4rem;
    padding-bottom: 0.2rem;
  }

  .UniversalSearchResultRow__info__subtitle {
    font-size: 1.1rem;
    color: #666666;
    font-weight: 400;
    padding-bottom: 0.5rem;
  }

  .UniversalSearchResultRow__mix__date {
    font-style: italic;
    margin-left: 0.2rem;
  }

  .UniversalSearchResultRow__info__text {
    font-size: 0.9rem;
    color: #666666;
    font-weight: 400;

    .track__order {
      font-weight: 500;
      margin-right: 0.2rem;
    }
  }
}

.ArtistSearchResult__mix-count {
  padding-top: 0.2rem;
  font-size: 1rem;
  color: #666666;
  font-weight: 600;
}

.ShowSearchResult__show-title {
}
.ShowSearchResult__show-artist {
  padding-top: 0.4rem;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 400;
}
