.MixesTable__list__row {
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  margin-left: 1.5rem;

  @media screen and (max-width: $screen-md) {
    margin-left: 0;
  }
}

.MixesTable__list__row__content {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0.8rem 0 1rem 0; // Compensating for Akagi Pro's weird line-height issues.

  h4 {
    white-space: nowrap;
  }
}

.MixesTable__list__row__meta {
  color: #7d7d7d;
  font-size: 1.2rem;
}

.MixesTable__listener-count {
  border: 1px solid #ddd;
  border-radius: 2px;
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.15em;
  line-height: 1;
  margin: 0 0 0 1rem;
  padding: 0.1rem 0.5rem;
  position: relative;
  top: -0.1rem;
  white-space: nowrap;
}

.MixesTable__list__row__ellipsis {
  align-items: center;
  display: flex;
  padding: 0 0.5rem;
}

.MixesTable__list__row__number {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-right: 0.5rem;
  width: 2.2rem;
}

// MOBILE size
@media screen and (max-width: $screen-sm) {
  .MixesTable__show_name {
    font-size: 2rem;
    font-weight: 500;

    a {
      color: #040404;

      &:hover {
        color: $color-primary;
      }
    }
  }

  .MixesTable__listener-count-container {
    align-items: center;
    display: flex;
  }

  .MixesTable__listener-count {
    border-radius: 100px;
    margin-right: 0.5rem;
    padding: 0.3rem 0.8rem;
  }

  .MixesTable__list__row__ellipsis {
    display: flex;
    height: auto;
    padding: 0;

    .Button {
      height: 100%;
      padding: 0 1.5rem;
    }
  }
}

// TOMBSTONE CSS
.MixesTable__tombstone__wrapper {
  margin-top: 0;

  :nth-child(odd) {
    .MixesTable__tombstone__artist {
      width: 8rem;
    }
  }
}

.MixesTable__tombstone {
  // height on mobile is 45
  height: 5rem;
  position: relative; // for border to show
}

.MixesTable__tombstone__chart {
  width: 2.2rem;
}

.MixesTable__tombstone__artist {
  width: 11rem;
}

@media screen and (max-width: $screen-xs) {
  .MixesTable__list__row {
    margin-left: 0;
  }
}
