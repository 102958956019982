.ButtonGroup {
  display: flex;
  margin: 1rem 0;
}

// VERTICAL BUTTONS
.ButtonGroup--vertical {
  display: block;

  &.ButtonGroup--fit {
    display: flex;
    flex-direction: column;
  }

  button,
  .Button,
  a.Button {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .Button {
    flex: 0;
  }
}

// HORIZONTAL BUTTONS
.ButtonGroup--horizontal {
  > * {
    margin: 0 0.5rem;
    flex: 0 1 auto;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &.ButtonGroup--center {
    justify-content: center;
  }
}

.ButtonGroup--expand {
  width: 100%;

  button,
  .Button,
  a.Button {
    flex: 1 1 auto;
  }
}

.ButtonGroup--collapse {
  .Button,
  a.Button {
    margin: 0;
    border-radius: 0;
  }
}

.ButtonGroup--fit {
  display: flex;
  height: 100%;

  .Button,
  a.Button {
    align-items: center;
    flex: 1;
    height: 100%;
  }
}

.ButtonGroup--bordered {
  .Button {
    border: solid 1px #ddd;
  }

  &.ButtonGroup--collapse.ButtonGroup--vertical .Button {
    border-bottom: none;

    &:first-child {
      border-radius: 0.2rem 0.2rem 0 0;
    }

    &:last-child {
      border-bottom: solid 1px #ddd;
      border-radius: 0 0 0.2rem 0.2rem;
    }
  }

  &.ButtonGroup--collapse.ButtonGroup--horizontal .Button {
    border-right: none;

    &:first-child {
      border-radius: 0.2rem 0 0 0.2rem;
    }

    &:last-child {
      border-right: solid 1px #ddd;
      border-radius: 0 0.2rem 0.2rem 0;
    }
  }

  .Button--transparent:hover {
    background: $color-gray--superlight;
  }
}
