.u__no-padding {
  padding: 0;
}

.align-vertically {
  display: flex;
  align-items: center;
  height: 100%;
}

.full-width {
  width: 100%;
}

.text-weight--heavy {
  font-weight: 700;
}

.u__no-margin--right {
  margin-right: 0;
}

.u__hidden {
  display: none;
}

.no-wrap {
  white-space: nowrap;
}

.p-relative {
  position: relative;
}

.justify-center {
  justify-content: center;
}

.d-none {
  display: none;
}

.preload-image {
  display: none;
}

@media screen and (max-width: $screen-sm) {
  .pl-xs-0 {
    padding-left: 0;
  }

  .pr-xs-0 {
    padding-right: 0;
  }

  .mb-xs-0 {
    margin-bottom: 0;
  }
}

$spaceamounts: (
  0.75,
  1,
  2,
  4,
  6
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{index($spaceamounts, $space)} {
      margin-#{$side}: #{$space}rem !important;
    }

    .p#{str-slice($side, 0, 1)}-#{index($spaceamounts, $space)} {
      padding-#{$side}: #{$space}rem !important;
    }
  }
}

@each $side in $sides {
  .m#{str-slice($side, 0, 1)}-0 {
    margin-#{$side}: 0 !important;
  }

  .p#{str-slice($side, 0, 1)}-0 {
    padding-#{$side}: 0 !important;
  }
}
