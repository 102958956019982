.EntityHeader {
  align-items: center;
  color: #fff;
  display: flex;
  margin-bottom: 3rem;
  padding: 3rem 1.5rem;
  position: relative;

  .media {
    margin-bottom: 0;
    position: relative;
    z-index: 3;
  }

  h1,
  h2,
  h3 {
    color: #fff;
  }
}

.EntityHeader--genre {
  min-height: 20rem;
}

.EntityHeader__container {
  padding: 0;
  width: 100%;
}

.EntityHeader__content {
  position: relative;
  z-index: 3;
}

.EntityHeader__description {
  margin: 0 auto;
  max-width: 60rem;
  position: relative;

  p {
    font-size: 1.4rem;
    line-height: 1.6;
    word-break: break-word;
  }
}

.EntityDescription__more-icon {
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  padding: 0.25rem 0.75rem;
}

.EntityDescription__more-icon-wrapper {
  display: inline-block;
  margin-top: 0.5rem;
  transition: margin-bottom 300ms linear;
  outline: none;
}

.EntityDescription.is-expanded {
  .EntityDescription__more-icon-wrapper {
    margin-bottom: 2.5rem;
  }

  .EntityDescription__more-icon svg {
    transform: rotate(180deg);
  }
}

.EntityHeader__title-container {
  margin-bottom: 1rem;
}

.EntityHeader__title {
  margin-bottom: 0;
  margin-left: -3px;
  font-size: 5.4rem;
  font-weight: 500;
  line-height: 1;
}

.EntityHeader__subtitle {
  font-style: italic;
  font-size: $font-size--medium;
}

.EntityHeader__category {
  margin-top: 0.4rem;
  @extend .label--small-caps;
}

.EntityHeader--genre .EntityHeader__canvas-container {
  background: #aaa;
  background-size: cover;
  background-repeat: no-repeat;

  &:after {
    background: rgba(0, 0, 0, 0.1);
  }
}

.EntityHeader__canvas-container {
  background-size: cover;
  background-position: center center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;

  &:after {
    background: rgba(0, 0, 0, 0.65);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.EntityHeader__show-more {
  position: absolute;
  left: 0;
  bottom: -1em;
  z-index: 3;
  background: #ff9900;
}

@media screen and (max-width: $screen-sm) {
  .EntityHeader {
    min-height: auto;
    margin-bottom: 1.5rem;
    padding: 1.5rem 0;
    position: relative;
  }

  .EntityHeader--genre {
    min-height: 12rem;
  }

  .EntityHeader__container {
    padding: 0 15px;
    width: 100%;
  }

  .EntityHeader__title {
    font-size: 3.8rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: $screen-md) {
  .EntityHeader__description {
    margin: 0;
  }
}
