.loader {
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.loader__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
  height: 32px;
  width: 32px;
}

.pace {
  bottom: 1px;
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 8;
}

.pace-progress {
  background: #fff;
  height: 2px;
  width: 100%;
  left: -100%;
  position: absolute;
}

.pace-inactive {
  display: none;
}

@media screen and (max-width: $screen-md) {
  .pace {
    z-index: 5;
  }
}
