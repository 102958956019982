.account-disconnect {
  display: inline;
  margin-left: 0.5rem;

  a {
    cursor: pointer;
  }

  button {
    color: #e26014;

    &:hover {
      text-decoration: underline;
    }
  }
}

button.acct-connection-toggle {
  padding: 0px;
}
