.AccountProAccess__artist {
  max-width: 23rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: $screen-sm) {
  .AccountProAccess__artist {
    max-width: 20rem;
  }
}
