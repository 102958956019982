.Mix-dropdown__container {
  display: flex;
  margin: 0 -0.5rem;
}

.Mix-dropdown__col {
  flex: 1 1 33.33%;

  &>div {
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: $screen-md) {
  .Mix-dropdown__col {
    .form-group {
      margin-bottom: 0;
    }
  }

  body.browse-header--show {
    .Mix-dropdown__col--artists,
    .Mix-dropdown__col--genres {
      display: none;
    }
  }

  body.browse-header--artist {
    .Mix-dropdown__col--shows,
    .Mix-dropdown__col--genres {
      display: none;
    }
  }

  body.browse-header--genre {
    .Mix-dropdown__col--artists,
    .Mix-dropdown__col--shows {
      display: none;
    }
  }

  body {
    &.browse-header--show,
    &.browse-header--artist,
    &.browse-header--genre {
      .Mix-dropdown__col {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .Mix-dropdown__container {
    display: block;
    margin: 0;
  }

  .Mix-dropdown__col {
    margin-bottom: 1rem;
    padding: 0;
    width: 100%;

    &>div {
      padding: 0;
    }
  }
}