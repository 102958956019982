.switch {
  background: #ddd;
  border: none;
}

.switch-toggle {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: none;
  height: 22px;
  margin: 2px;
  width: 22px;
  left: -12px;
}

.switch.on {
  background: $color-primary;

  .switch-toggle {
    left: 12px;
  }
}

.switch.disabled {
  background: lighten(#ddd, 5%);
  &.on {
    background: lighten($color-primary, 15%);
  }

  &:hover {
    cursor: not-allowed;
  }
}
