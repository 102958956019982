.section {
  margin: 6rem 0;
}

.section--xsmall {
  margin: 3rem 0;
}

.section--small {
  margin: 4rem 0;
}

.section--large {
  margin: 8rem 0;
}

.section,
.section--small,
.section--large {
  &:first-child {
    margin-top: 0;
  }
}

.section.section--margin-top:first-child {
  margin-top: 6rem;
}

.section--small.section--margin-top:first-child {
  margin-top: 4rem;
}

@media screen and (max-width: $screen-sm) {
  .section,
  .section--small {
    margin: 3rem 0;
  }

  .section--large {
    margin: 6rem 0;
  }

  .section.section--margin-top:first-child {
    margin-top: 3rem;
  }

  .section--small.section--margin-top:first-child {
    margin-top: 3rem;
  }
}
