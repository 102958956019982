.ArtistBrowse {
  display: flex;
  flex-direction: column;
  flex: 1;

  .NavTabGroup__title {
    display: none;
  }

  .TabbedHeader__fixed-header {
    .NavTabGroup__title {
      display: block;
      height: 0.9rem;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .ArtistBrowse {
    .TextExpander {
      margin-bottom: 3rem;
    }
  }
}

@media screen and (max-width: $screen-md) {
  .ArtistBrowse {
    .TextExpander {
      margin-bottom: 2rem;
    }
  }
}
