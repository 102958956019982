h1,
h2,
h3 {
  color: #333;
  font-weight: $font-weight--regular;
  margin-bottom: 0.5em;
}

h4,
h5,
h6 {
  color: #555;
  font-weight: $font-weight--heavy;
  margin-bottom: 0.3em;
}

.header--xxlarge {
  font-size: $font-size--xxlarge;
}

h1,
.header--xlarge {
  font-size: $font-size--xlarge;
}

h2,
.header--large {
  font-size: $font-size--large;
  line-height: 1.3;
}

h3,
.header--medium {
  font-size: $font-size--medium;
  font-weight: $font-weight--heavy;
  line-height: 1.3;
}

h4,
.header--small {
  font-size: $font-size--small;
  font-weight: $font-weight--heavy;
}

.header--bordered {
  border-bottom: 1px solid $color-gray--light;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
}

.Text--large {
  font-size: 1.6rem;
}

.text--light {
  font-weight: 300;
}

.label--small-caps {
  font-size: $font-size--xsmall;
  font-weight: $font-weight--regular;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

a {
  color: $color-links;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $color-links;
    // text-decoration: none;
  }

  &.is-disabled {
    &:hover,
    &:active,
    &:focus {
      cursor: default;
    }
  }
}

p {
  line-height: 1.5;
}

em {
  font-style: italic;
}

strong {
  font-weight: $font-weight--heavy;
}

i,
em,
blockquote {
  font-style: italic;
}

u {
  text-decoration: underline;
}

strike {
  text-decoration: line-through;
}

/* Content Container & Overlay ================================================================================ */
#content,
.content {
  padding: 0 0 8rem 0;
  position: relative;
}

.content-section {
  margin: 3rem 0;

  h1,
  h2,
  h3 {
    color: #666;
  }
}

.ArticleText {
  font-size: 1.6rem;
  line-height: 1.7;
  margin-bottom: 8rem;

  p,
  ol,
  ul {
    line-height: 1.7;
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1.5em;
  }

  p + ul,
  p + ol {
    margin-top: -1em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ol,
  ul {
    padding-left: 2rem;
    margin-bottom: 1.5em;
  }

  li {
    margin-bottom: 0.375em;
  }

  li p:last-child {
    margin-bottom: 0;
  }

  h1 {
    font-size: 3.6rem;
    font-weight: 600;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    margin-top: 1.5em;
  }

  h1 + p {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    margin-top: -2.5rem;
  }
}
