.ScrollBox {
  height: auto;
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
}

.Tracklist {
  display: flex;
  overflow: hidden;
}

.Tracklist__container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.Tracklist__list {
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  height: 170px;
  padding-bottom: 20px;
  width: 100%;
}

.Tracklist__scroll {
  height: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Tracklist__scroll-btn {
  display: block;
  height: 100%;
  opacity: 0.75;
  position: absolute;
  top: 0;
  transition: opacity 0.25s ease-in-out;
  width: 5rem;
  z-index: 3;

  &:hover {
    opacity: 1;

    &:after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
}

.Tracklist__scroll-btn--backward {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  left: 0;

  &:after {
    background: url('/images/icon-left-arrow.svg') center no-repeat;
    background-size: 1.8rem;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.2;
    position: absolute;
    transition: opacity 0.25s ease-in-out;
    width: 5rem;
  }
}

.Tracklist__scroll-btn--forward {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  right: 0;

  &:after {
    background: url('/images/icon-right-arrow.svg') center no-repeat;
    background-size: 1.8rem;
    content: '';
    display: block;
    height: 100%;
    opacity: 0.2;
    right: 0;
    position: absolute;
    transition: opacity 0.25s ease-in-out;
    width: 5rem;
  }
}

@media screen and (max-width: $screen-sm) {
  .Tracklist__list {
    flex-direction: column;
    height: 100%;
    padding: 0;

    &:before,
    &:after {
      display: none;
    }
  }

  .Tracklist__scroll {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;

    &:before,
    &:after {
      display: none;
    }
  }

  .Tracklist__scroll-btn {
    display: none;
  }
}
