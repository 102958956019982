.Mix-list {
  list-style: none;
}

.Mix-list__item {
  align-items: center;
  background: rgba(255, 255, 255, 0);
  display: flex;
  min-height: 6.3rem;
  min-width: 0;
  overflow: hidden;
  padding: 0.3rem 1rem;
  position: relative;
  transition: background 0.2s linear;

  &:nth-child(2n) {
    background: rgba(255, 255, 255, 0.02);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  .Mix__position {
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;
    font-size: 2rem;
    font-weight: 600;
    padding: 0 0.3rem;
    text-align: center;
    width: 4rem;
  }

  .Mix__banner {
    margin-right: 1rem;
    padding: 0.3rem 0;

    img {
      height: 4.8rem;
    }
  }

  .Mix__info {
    // Flexbox requires a min-width value in order to collapse
    // when a child element is overflowing the container.
    flex: 1 1 auto;
    min-width: 0;
    overflow: hidden;
    padding: 0.45rem 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Mix__info__artist {
    a {
      color: #ffffff;
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
      margin: 0.4rem 0 0.5rem 0;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .Mix__info__meta {
    display: flex;
    font-weight: 300;
    margin: 0.5rem 0;

    a {
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .Mix__info__listens {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  .Play-button {
    display: flex;

    div {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .Mix-list__item {
    .Mix__position {
      flex: 0 0 auto;
      width: 3.5rem;
    }

    .Mix__info__meta__episode-date {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .Mix__info__listens {
      display: none;
    }

    .Mix__info__meta-part {
      display: none;
    }
  }
}
