.FlatListFooter {
  align-items: center;
  border-top: 0.1rem #ddd solid;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 1.5rem 0;
  padding: 6rem 0 0 0;
  text-align: center;
}

@media screen and (min-width: $screen-sm) {
  .FlatListFooter {
    justify-content: flex-start;
    text-align: left;
  }
}
