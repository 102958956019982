$component-min-height--desktop: 60rem;
$component-min-height--mobile: 46rem;

.Radio-page {
  left: 0;
  min-height: $component-min-height--desktop;
  top: 0;
  width: 100%;
}

.Radio-page__background {
  height: 100%;
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.Radio-page__tiles {
  padding-top: 8rem;
  position: relative;
  z-index: 3;
}

.Radio-page--single {
  min-height: 100%;
}

.Radio-page__tiles--single {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding: 5rem 0;
  position: absolute;
  width: 100%;
}

.Overlay {
  background: none;
}

.Overlay__container {
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  color: $color-gray--superdark;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: $component-min-height--desktop;
  opacity: 0;
  padding: 2rem;
  position: absolute;
  top: 0;
  transition: opacity 0.5s linear;
  width: 100%;
  z-index: 6;

  &.is-visible {
    opacity: 1;
  }
}

.Tile {
  display: block;
  width: 100%;
}

.Tile__inner__content {
  border-radius: 0.4rem;
  overflow: hidden;
}

.Tile__inner__header__title {
  color: #fff;
  font-size: $font-size--large;
  font-weight: $font-weight--light;
  margin-bottom: 1rem;
}

@media screen and (max-width: $screen-sm) {
  .Radio-page__tiles {
    padding-top: 6rem;
  }
}
