.SearchResults__wrapper {
  padding: 0 5rem;
  max-width: 113rem;

  header {
    padding: 1rem 0;
    @media screen and (max-width: $screen-xs) {
      display: none;
    }
  }

  @media screen and (max-width: $screen-md) {
    padding: 0 2.5rem;
  }

  @media screen and (max-width: $screen-xs) {
    padding: 0 0.7rem;
  }
}

.SearchResults__results {
  display: flex;
  margin-bottom: 6rem;
  min-height: 20rem;

  // force to use padding in place of margin to optimize active nav tab highlighting
  .section {
    padding-bottom: 6rem;

    &.section.section--small {
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 4rem;
    }
  }

  @media screen and (max-width: $screen-xs) {
    margin-left: 0;
    min-height: auto;
  }

  .UniversalSearchResults__results {
    overflow-y: unset;
    overflow: hidden;
  }
}
