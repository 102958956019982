html {
  background: #fff;
  font-size: 10px;
  height: 100%;
}

body {
  color: $color-gray;
  font-size: 1.4rem;
  font-weight: $font-weight--regular;
  height: 100%;
  text-rendering: optimizeLegibility;
  position: relative;

  &.no-scroll {
    overflow: hidden;
    // position: fixed; // Prevent body scroll on iOS
    width: 100%;
  }
}

.is-hidden {
  display: none;
}

input[type='submit'].is-hidden {
  display: block;
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.divider {
  font-weight: $font-weight--heavy;
  font-size: 2rem;
  margin: 2rem 0;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.hairline-divider {
  width: 100%;
  height: 0.1rem;
  border-bottom: 1px solid #ddd;
  margin: 2rem 0;
}

// see "retinaScreenDetect.js" for more details
// to use, apply className of .hairline-border to element with hairline border
.retina-screen .hairline-border {
  border-width: 0.5px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}
.clearfix {
  display: inline-block;
}
/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* close commented backslash hack */

ol.numbered {
  line-height: 1.5;
  list-style-type: decimal;
  padding-left: 2rem;

  li {
    margin-bottom: 0.25rem;
  }
}

@media screen and (min-width: $screen-sm) {
  body {
    // Prevents weird nav overlay bug on touchscreens when
    // scrolling at the edge of the page.
    &.has-open-tracklist {
      .content-main {
        padding-bottom: 25rem;
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  body {
    // Prevents weird nav overlay bug on touchscreens when
    // scrolling at the edge of the page.
    &.has-overlay,
    &.has-open-tracklist {
      .site-nav {
        position: fixed;
        top: 0;
        width: 100%;
      }
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.underline {
  text-decoration: underline;
}

#action-menus-root,
#portals-root {
  left: 0;
  position: absolute;
  top: 0;
}

.rc-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 2px;
  font-size: 14px;
  opacity: 1;
  padding: 1rem;
}
