@import './Input/Input';

.FormElement--light {
  input {
    background: #ffffff;
    border: 0.1rem solid #dddddd;
    box-sizing: border-box;
    color: #555;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0.8rem 1rem;

    &:hover {
      background-color: #ffffff;
    }

    &.Input--error {
      border-color: $color-danger;
    }
  }

  label {
    font-size: 1.3rem;
    color: #444;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  input[type='text']:disabled {
    background-color: #eeeeee;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.FormElement--large {
  input {
    font-size: 1.6rem;
    padding: 1.1rem 1rem 0.9rem;
  }
}

.FormElement--dark {
}

.FormElement--with-icon-prepend {
  input {
    padding-left: 4rem;
  }
}

.FormElement--with-icon-append {
  input {
    padding-right: 4rem;
  }
}

.FormElement--rounded {
  input {
    border-radius: 100px;

    &:focus {
      border-radius: 100px;
    }
  }
}
