.track {
  background: $color-gray--superdark;
  // border-left: 1px solid lighten($color-gray--superdark, 7.5%);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.5rem;
  position: relative;
  transition: background 0.25s linear, background-image 0.25s linear;

  &:first-child {
    border-left: none;
    margin-left: 0;
  }

  &:hover {
    // background: lighten($color-gray--superdark, 5%);

    .track__cover {
      opacity: 1;
    }
  }

  &:first-child {
    padding-left: 20rem;
  }

  &:last-child {
    padding-right: 20rem;
  }

  &:nth-child(5n-4) {
    .track__cover {
      background-color: #0fa4fe;
    }
  }
  &:nth-child(5n-3) {
    .track__cover {
      background-color: #e53041;
    }
  }
  &:nth-child(5n-2) {
    .track__cover {
      background-color: #f3c322;
    }
  }
  &:nth-child(5n-1) {
    .track__cover {
      background-color: #81bf28;
    }
  }
  &:nth-child(5n-0) {
    .track__cover {
      background-color: #8841c4;
    }
  }

  &.is-playing {
    background: lighten($color-gray--superdark, 5%);

    &:after {
      background: $color-primary;
      content: '';
      display: block;
      height: 0.3rem;
      left: 0;
      max-width: 37.1rem;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:first-child {
      right: 0;
      left: auto;

      &:after {
        left: auto;
        right: 0;
      }
    }
  }

  &.is-previous,
  &.is-next {
    &:after {
      background: $color-primary;
      content: '';
      display: block;
      height: 0.3rem;
      position: absolute;
      top: 0;
      width: 10.4rem;
    }
  }

  &.is-previous {
    background-image: linear-gradient(
      to left,
      lighten($color-gray--superdark, 5%) 0,
      lighten($color-gray--superdark, 2%) 10.4rem,
      $color-gray--superdark 10.4rem,
      $color-gray--superdark 100%
    );

    &:after {
      right: 0;
    }
  }

  &.is-next {
    background-image: linear-gradient(
      to right,
      lighten($color-gray--superdark, 5%) 0,
      lighten($color-gray--superdark, 2%) 10.4rem,
      $color-gray--superdark 10.4rem,
      $color-gray--superdark 100%
    );

    &:after {
      left: 0;
    }
  }
}

.track--horizontal {
  display: flex;
  flex-direction: row;
}

.track__cover {
  flex: 0 0 auto;
  height: 14rem;
  opacity: 0.85;
  position: relative;
  transition: opacity 0.25s linear;
  width: 14rem;

  &:after {
    background-image: url('/images/icon-track-cover.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.track__meta {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  line-height: 1;
  padding-left: 1rem;
  width: 20rem;
}

.track__id {
  font-size: $font-size--medium;
  font-weight: $font-weight--light;
  margin-bottom: 0.5rem;
}

.track__artist {
  font-size: $font-size--medium;
  font-weight: $font-weight--heavy;
  line-height: 1.2;
  margin-bottom: 0.7rem;
  max-height: 3.6em;
  overflow: hidden;
}

.track__title {
  font-size: $font-size--xsmall;
  line-height: 1.2;
  flex: 1;
}

.track__label {
  @extend .label--small-caps;
}

@media screen and (max-width: $screen-sm) {
  .track {
    padding: 0.75rem 1rem;
    width: 100%;

    &:first-child {
      padding-left: 1rem;
      padding-top: 0.75rem + 4.4rem; // add header height
    }

    &:last-child {
      padding-left: 1rem;
    }

    &.is-playing {
      &:first-child {
        &:after {
          left: 0;
          right: auto;
        }
      }

      &:after {
        width: 3px;
        height: 100%;
      }
    }

    &.is-previous {
      background-image: linear-gradient(
        to top,
        lighten($color-gray--superdark, 5%) 0,
        lighten($color-gray--superdark, 5%) 4rem,
        $color-gray--superdark 4rem,
        $color-gray--superdark 100%
      );

      &:after {
        left: 0;
        bottom: 0;
        right: auto;
        top: auto;
        width: 0.3rem;
        height: 4rem;
      }
    }

    &.is-next {
      background-image: linear-gradient(
        to bottom,
        lighten($color-gray--superdark, 5%) 0,
        lighten($color-gray--superdark, 5%) 4rem,
        $color-gray--superdark 4rem,
        $color-gray--superdark 100%
      );

      &:after {
        left: 0;
        top: 0;
        right: auto;
        width: 0.3rem;
        height: 4rem;
      }
    }
  }

  .track__cover {
    height: 8rem;
    width: 8rem;
  }

  .track__id {
    font-size: $font-size--medium--mobile;
    margin-bottom: 0.5rem;
  }

  .track__artist {
    font-size: $font-size--medium--mobile;
    margin-bottom: 0.3rem;
    max-height: 2.4em;
  }

  .track__title {
    font-size: $font-size--small--mobile;
  }

  .track__label {
    font-size: $font-size--xsmall--mobile;
    font-weight: $font-weight--regular;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}
