:root {
  --react-international-phone-selected-dropdown-item-background-color: #{lighten($color-primary, 45%)};
}

.react-international-phone-country-selector-dropdown__list-item--focused {
  color: $color-primary !important;
}

.react-international-country-list-container::-webkit-scrollbar, .proton__menu-list::-webkit-scrollbar {
  width: .6rem;
  overflow: hidden;
}

.react-international-country-list-container::-webkit-scrollbar-thumb, .proton__menu-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.react-international-phone-input,
.react-international-phone-country-selector-button {
  transition: all 0.1s ease-in-out;
}

.react-international-phone-input:hover {
  border: 1px solid #B1B1B1 !important;
  border-left: 1px solid #B1B1B1;
}

.react-international-phone-country-selector-button:hover {
  border: 1px solid #B1B1B1 !important;
}

.react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: #F7F8F9 !important;
}
.react-international-phone-country-selector-dropdown__list-item--focused:hover {
  background-color: var(--react-international-phone-selected-dropdown-item-background-color) !important;
}

.react-international-phone-country-selector-dropdown__list-item-flag-emoji {
  display: none;
}