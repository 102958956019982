.player__volume-trigger {
  cursor: pointer;
  flex: 0 0 auto;
  height: 6rem;
  position: relative;
  user-select: none;
  width: 4rem;

  &:hover,
  &:active,
  &:focus,
  &.is-open {
    .player__volume-container {
      padding-bottom: 14rem;
    }
  }

  &:before {
    position: absolute;
    width: 100%;
    height: 72%;
    top: 28%;
    background: $color-gray--superdark;
    display: block;
    content: '';
    z-index: 4;
  }
}

.player__volume-container {
  background: $color-gray--superdark;
  bottom: 0rem;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  transition: padding 0.1s linear;
  z-index: 3;
}

.player__volume-inner {
  background: #343434;
  height: 8.5rem;
  margin: 1rem 1rem 3.5rem 1rem;
  position: relative;
  width: 1.8rem;
}

.player__volume-level {
  background: $color-primary;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.player__volume-icon {
  display: flex;
  align-items: center;
  height: 3rem;
  position: relative;
  text-align: center;
  top: 1.5rem;
  z-index: 5;

  svg {
    max-width: 2rem;
    position: relative;
    margin: 0 auto;
    opacity: 0.9;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  &.is-muted {
    .volume-1,
    .volume-2,
    .volume-3 {
      display: none;
    }
  }

  &.is-volume-1 {
    .volume-0,
    .volume-2,
    .volume-3 {
      display: none;
    }
  }

  &.is-volume-2 {
    .volume-0,
    .volume-3 {
      display: none;
    }
  }

  &.is-volume-3 {
    .volume-0 {
      display: none;
    }
  }
}
