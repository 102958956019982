.ListItem {
  color: #666;
  display: flex;
  font-size: 1.4rem;
  justify-content: flex-start;
  padding: 0.5rem 0;
  position: relative;
  transition: opacity 0.3s;
}

.ListItem__section {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  margin: 0 1.5rem 0 0;
  width: 15rem;

  &:first-child {
    flex: 0 0 auto;
    width: 30rem;
  }

  @media screen and (max-width: $screen-md) {
    width: 20rem;

    &:first-child {
      width: 20rem;
    }
  }
}

.ListItem__content {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  line-height: 1.2;
}

.ListItem__image {
  background-color: $color-gray--light;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  border-radius: 100rem;
  height: 4rem;
  margin-right: 1rem;
  overflow: hidden;
  width: 4rem;
}

.ListItem__image-placeholder {
  height: 4rem;
}

.ListItem__title {
  color: #333;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
  white-space: nowrap;
}

.ListItem__subtitle {
  color: $color-gray;
  font-size: 1.2rem;
  font-weight: 300;

  a {
    color: $color-gray;
  }
}

.ListItem__aside {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  padding: 0;
  margin-left: 1rem;
}

.ListItem__disabled__message {
  align-items: center;
  box-sizing: content-box;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding-left: 30px;
  position: absolute;
  right: 2rem;
  text-align: right;
  top: 0.2rem;
  z-index: 3;

  .LabelText {
    color: #000; // Override so it appears gray within the lowered opacity row
  }
}

// *** DESKTOP styling ***
@media screen and (min-width: $screen-xs) {
  .ListItem {
    padding: 0.5rem 0;

    .ListItem__image,
    .Tombstone__avatar {
      flex: 0 0 auto;
      height: 5rem;
      width: 5rem;
    }
  }

  .ListItem__section {
    width: 15rem;
  }

  // only show title as clickable if not mobile
  .ListItem__title a,
  .ListItem__subtitle a,
  a .ListItem__title,
  a .ListItem__subtitle {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.ListItem__content--aside {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.ListItem__title__container {
  margin: auto;
}
