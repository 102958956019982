input.form-control {
  box-shadow: none !important;
}

.SearchInput {
  input {
    height: auto;
    padding: 0.8rem 1rem 0.8rem 4rem;
  }
}

.Form--deprecated {
  .form-control,
  .form-control:focus {
    box-shadow: none;
    border-radius: 2px;
    height: auto;
  }

  input,
  textarea {
    position: relative;
    padding: 5px 10px;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: $font-weight--heavy;
    color: #fff;
    border: none;
    background-color: $color-gray;
    border-radius: 0;
  }

  select {
    position: relative;
    border-radius: 0;
    outline: none;
    box-shadow: none;
  }

  .form-group__select-clear {
    background-color: $color-gray--medium;
    background-image: url('/images/icon-close.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    height: 3.2rem;
    top: 0.8rem;
    right: 0.8rem;
    width: 3.2rem;
    position: absolute;
    z-index: 2;
  }

  .form-group.is-selected {
    .form-group__select-clear {
      display: block;
    }
  }

  input {
    &:hover {
      background-color: #444;
    }
  }

  ::-webkit-input-placeholder {
    opacity: 0.6;
    padding: 3px 0;
  }

  :-ms-input-placeholder {
    opacity: 0.6;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #666 inset !important;
    -webkit-text-fill-color: #fff !important;
  }

  input.parsley-error:-webkit-autofill {
    box-shadow: 0 0 0px 1000px $color-error inset !important;
    -webkit-text-fill-color: #fff !important;
  }

  .form-group__label {
    margin: 0.5rem 0 0 0;
    font-size: $font-size--medium;
    font-weight: $font-weight--regular;
    color: $color-gray;
    line-height: 1;
  }

  .form-group__trigger {
    font-weight: 700;
    color: $color-gray;
    font-size: $font-size--large;
    display: inline-block;
    margin-top: 0.8rem;
  }

  a.form-group__trigger,
  button.form-group__trigger {
    color: $color-primary;
    &:hover {
      box-shadow: inset 0 -1px 0 #fff, inset 0 -2px 0 #ff803a;
    }
  }

  .form--block {
    label {
      color: #e7e7e7;
      float: left;
      font-size: $font-size--small;
      font-weight: $font-weight--regular;
      height: 0;
      padding: 0 1.2rem;
      position: relative;
      text-transform: uppercase;
      top: 1.2rem;
      width: 100%;
      z-index: 3;
    }

    span.error {
      display: inline-block;
    }

    span.error-msg-delimiter {
      margin-left: 0.65em;
      margin-right: 0.65em;
      color: hsla(0, 0%, 100%, 0.35);
    }

    .form-errors {
      background: $color-error;
      color: #fff;
      line-height: 1.4;
      margin-bottom: 1rem;
      padding: 1rem;

      a {
        color: #fff;
        text-decoration: underline;
        &:hover {
          color: #eee;
        }
      }
    }

    .form-errors__actions {
      margin-top: 1rem;

      button {
        background-color: rgba(255, 255, 255, 0.1);

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }

        // NOTE: css below is similar to .form-group__addon--button css
        border: 1px solid #fff;
        border-radius: 2px;
        color: #fff;
        display: block;
        font-size: 1.1rem;
        font-weight: $font-weight--heavy;
        letter-spacing: 0.15em;
        padding: 0.6rem 0.8rem 0.4rem;
        text-transform: uppercase;
        transition: background-color 0.15s ease-in-out;
      }
    }

    .parsley-errors-list {
      opacity: 1;

      li {
        display: inline-block;
      }
      li + li {
        &:before {
          content: ', ';
        }
      }
    }
    .form-group {
      margin-bottom: 1rem;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .form-group.checkbox {
      align-items: center;
      background: #f9f9f9;
      border: 1px solid #eeeeee;
      display: flex;
      height: 4.8rem;
      margin-bottom: 1rem;
      padding: 0 1rem;

      label {
        // override label css for inputs
        font-size: 1.6rem;
        height: auto;
        padding: 0;
        position: relative;
        text-transform: none;
        top: auto;

        // add checkbox specific css
        span {
          color: #666666;
          margin-left: 1rem;
          margin-top: 0.2rem;
        }
      }
    }

    input[type='text'],
    input[type='password'],
    input[type='email'],
    textarea {
      background: #666666;
      border: 2px solid #666666;
      color: #fff;
      font-size: $font-size--medium;
      height: auto;
      padding: 2.5rem 1rem 0.5rem;
      position: relative;
      width: 100%;
      transition: none;
      -webkit-appearance: none;

      &:focus,
      &:active {
        border: 2px solid $color-primary;
        box-shadow: inset 0 0 0 2px #fff;
        outline: none;
      }

      &.parsley-error {
        background: $color-error;
        border: 2px solid $color-error;
      }
    }

    textarea[type='textarea'] {
      background: #666;
    }

    .form-control-link {
      color: #fff;
      position: absolute;
      right: 1.5rem;
      top: 1.2rem;
    }

    textarea {
      padding-top: 2.5rem;
      padding-bottom: 3rem;
    }

    input[disabled] {
      &:hover,
      &:focus,
      &:active {
        border: 2px solid #666;
        box-shadow: none;
      }
    }

    .parsley-errors-list {
      border-left: 1px solid rgba(255, 255, 255, 0.35);
      font-size: inherit;
      line-height: inherit;
      margin: 0 0 0 0.75rem;
      padding-left: 0.75rem;
    }

    textarea.textarea--light {
      background: #ffffff;
      border: 0.1rem solid #dddddd;
      box-sizing: border-box;
      color: #555;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 1rem;
    }
  }

  label.disabled {
    user-select: none;
  }

  .form-group__addon {
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 1.1rem;
    font-weight: $font-weight--heavy;
    height: 100%;
    letter-spacing: 0.15em;
    position: absolute;
    right: 1.5rem;
    text-transform: uppercase;
    top: 0;
    z-index: 3;

    &:hover {
      color: $color-primary;
    }
  }

  .Button.Button--large {
    font-size: 2.4rem;
    height: 5.4rem;
  }

  .form-group__addon--button,
  .btn--addon {
    border: 1px solid #ddd;
    border-radius: 2px;
    color: #ddd;
    display: block;
    font-size: 1.1rem;
    font-weight: $font-weight--heavy;
    letter-spacing: 0.15em;
    opacity: 0.75;
    padding: 0.6rem 0.8rem 0.4rem;
    position: absolute;
    right: 1.5rem;
    text-transform: uppercase;
    top: 1.8rem;
    transition: opacity 0.15s ease-in-out;
    z-index: 3;

    &:hover,
    &:focus {
      border-color: #fff;
      color: #fff;
      opacity: 1;
      outline: none;
      text-decoration: none;
    }
  }

  .btn--addon {
    border: 1px solid $color-primary;
    color: $color-primary;

    &:hover,
    &:focus {
      border: 1px solid $color-primary;
      color: $color-primary;
      opacity: 1;
      outline: none;
    }
  }

  @media screen and (max-width: $screen-xs) {
    .form--block {
      label {
        font-size: $font-size--small--mobile;
      }

      input[type='text'],
      input[type='password'],
      input[type='email'],
      textarea {
        font-size: $font-size--large--mobile;
        padding-top: 2.3rem;
      }

      textarea {
        padding-left: 1rem;
      }
    }

    .form-group__label {
      font-size: $font-size--medium--mobile;
    }

    .form-group__trigger {
      font-size: $font-size--large--mobile;
    }

    .form-group__addon--button {
      top: 1.6rem;
    }

    .form-group .select2-container .select2-choice .select2-arrow b {
      margin-top: 2.2rem;
    }
  }

  .submit-message {
    margin-top: 1rem;
    color: #999999;
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}

textarea {
  &:focus,
  &:active {
    outline: none;
  }
}
