.BlurredBackground {
  &:after {
    background: rgba(0, 0, 0, 0.6);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
  }
}

.BlurredBackground__canvas {
  height: 100vh;
  opacity: 0;
  position: absolute;
  transition: opacity 1s ease-out;
  width: 100vw;

  &.is-visible {
    opacity: 1;
  }
}
